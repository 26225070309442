import React from 'react';

import TransparentBox from '../../../components/transparentBox/TransparentBox';
import ResponsiveVimeoVideo from '../../../components/responsiveVimeoVideo/ResponsiveVimeoVideo';
import blob from '../../../assets/images/blobs/blob_1.png';

const Video = () => {
  return (
    <section id="video" className="px-1 md:px-3">
      <img
        src={blob}
        alt="Blob"
        className="blob top-[-25%] right-[-30%] md:top-[-35%] md:right-[-30%]"
      />
      <TransparentBox extraClass="mt-14">
        <p className="text-base md:text-2xl uppercase">
          <span className="font-wskf tracking-[2px] text-3xl md:text-4xl pr-1">qu’est-ce que</span>{' '}
          la 47e finale nationale WorldSkills ?
        </p>
        <p className="text-md md:text-base">
          La Finale Nationale WorldSkills 2023, nommée historiquement Olympiade des Métiers, est une
          vitrine extraordinaire qui permet de découvrir près de 70 métiers. Plus de 800 jeunes de
          moins de 23 ans se mesureront dans des situations professionnelles réelles du 14 au 16
          septembre 2023 à Lyon. Elle permet aux élèves de s’embarquer pour une première découverte
          émotionnelle de la diversité et de la richesse des métiers.
        </p>
        <p className="text-base md:text-2xl uppercase">
          Les WorldSkills en{' '}
          <span className="font-wskf tracking-[2px] text-3xl md:text-4xl pl-1">90 secondes</span>
        </p>
        <div
          className="relative w-full overflow-hidden border-none mx-auto"
          style={{
            maxWidth: '100%',
            width: '100%'
          }}>
          <ResponsiveVimeoVideo
            url={
              'https://player.vimeo.com/video/796027338?h=f09b0755d8&color=ff9933&title=0&byline=0&portrait=0'
            }
          />
        </div>
      </TransparentBox>
    </section>
  );
};

export default Video;
