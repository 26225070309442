import React from 'react';

const Hero = () => {
  return (
    <section id="hero" className="px-1 md:px-3">
      <div className="pt-20 uppercase">
        <p className="text-xl md:text-4xl pb-0">
          Démarrer l'année{' '}
          <span className="font-wskf tracking-[2px] text-3xl md:text-5xl normal-case">
            sCoLaIRe
          </span>{' '}
          en s’ouvrant sur Les{' '}
          <span className="font-wskf tracking-[2px] text-3xl md:text-5xl normal-case">méTIeRs</span>
        </p>
        <span className="block border-b-[1px] my-4"></span>
        <p className="text-lg md:text-2xl">
          Profitez de la 47e compétition WoRLDSkILLs du 14 au 16 septembre
        </p>
        <p className="text-lg md:text-2xl">
          pour découvrir la Diversité et la{' '}
          <span className="font-wskf tracking-[2px] text-2xl md:text-3xl normal-case">
            RIcHesse
          </span>{' '}
          des{' '}
          <span className="font-wskf tracking-[2px] text-2xl md:text-3xl normal-case">méTIeRs</span>
        </p>
      </div>
    </section>
  );
};

export default Hero;
