import * as Yup from 'yup';

export const scolar_schema = Yup.object().shape({
  schoolData: Yup.object().shape({
    name: Yup.string().required('REQUIS'),
    address: Yup.string().required('REQUIS'),
    postalCode: Yup.string().required('REQUIS').max(8, 'Code Trop long'),
    city: Yup.string().required('REQUIS'),
    region: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .required('REQUIS'),
    academy: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .required('REQUIS'),
    type: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .required('REQUIS')
  }),
  responsible: Yup.array().of(
    Yup.object().shape({
      responsibleName: Yup.string().required('REQUIS'),
      responsibleFirstName: Yup.string().required('REQUIS'),
      responsibleFunction: Yup.string().required('REQUIS'),
      responsibleEmail: Yup.string().email(`L'email doit être un email valide`).required('REQUIS'),
      responsibleTelephone: Yup.string()
        .required('REQUIS')
        .matches(/^[0-9]{9,11}$/, 'Numéro de téléphone invalide'),
      responsibleMissionFuture: Yup.string().required('REQUIS'),
      classes: Yup.array().of(
        Yup.object().shape({
          classLevel: Yup.string().required('REQUIS'),
          classNumbers: Yup.string().required('REQUIS'),
          classVisitor: Yup.string().required('REQUIS'),
          classVisitDay: Yup.string().required('REQUIS'),
          classLunchSite: Yup.string(),
          classEstimateTime: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string()
            })
            .required('REQUIS')
        })
      )
    })
  )
});

export const groupe_schema = Yup.object().shape({
  schoolData: Yup.object().shape({
    name: Yup.string().required('REQUIS'),
    address: Yup.string().required('REQUIS'),
    postalCode: Yup.string().required('REQUIS').max(8, 'Code Trop long'),
    city: Yup.string().required('REQUIS'),
    region: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string()
      })
      .required('REQUIS'),
    type: Yup.string().required('REQUIS')
  }),
  responsible: Yup.array().of(
    Yup.object().shape({
      responsibleName: Yup.string().required('REQUIS'),
      responsibleFirstName: Yup.string().required('REQUIS'),
      responsibleFunction: Yup.string().required('REQUIS'),
      responsibleEmail: Yup.string().email(`L'email doit être un email valide`).required('REQUIS'),
      responsibleTelephone: Yup.string()
        .required('REQUIS')
        .matches(/^[0-9]{9,11}$/, 'Numéro de téléphone invalide'),
      responsibleMissionFuture: Yup.string().required('REQUIS'),
      groupe: Yup.array().of(
        Yup.object().shape({
          groupeDescription: Yup.string().required('REQUIS'),
          groupeNumbers: Yup.string().required('REQUIS'),
          groupeVisitor: Yup.string().required('REQUIS'),
          groupeVisitDay: Yup.string().required('REQUIS'),
          groupeLunchSite: Yup.string(),
          groupeEstimateTime: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string()
            })
            .required('REQUIS')
        })
      )
    })
  )
});

export const digital_schema = Yup.object().shape({
  name: Yup.string().required('REQUIS'),
  address: Yup.string().required('REQUIS'),
  postalCode: Yup.string().required('REQUIS'),
  city: Yup.string().required('REQUIS'),
  region: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('REQUIS'),
  academy: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('REQUIS'),
  type: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('REQUIS'),
  responsibleFirstName: Yup.string().required('REQUIS'),
  responsibleLastName: Yup.string().required('REQUIS'),
  responsibleEmail: Yup.string().email(`L'email doit être un email valide`).required('REQUIS'),
  responsibleFunction: Yup.string().required('REQUIS'),
  responsibleTelephone: Yup.string()
    .required('REQUIS')
    .matches(/^[0-9]{9,11}$/, 'Numéro de téléphone invalide')
});

export const scolar_initial_value = {
  schoolData: {
    name: '',
    address: '',
    postalCode: '',
    city: '',
    region: '',
    academy: '',
    type: ''
  },
  responsible: [
    {
      responsibleName: '',
      responsibleFirstName: '',
      responsibleFunction: '',
      responsibleEmail: '',
      responsibleTelephone: '',
      responsibleMissionFuture: '',
      classes: [
        {
          classLevel: '',
          classNumbers: '',
          classVisitor: '',
          classVisitDay: '',
          classLunchSite: '',
          classEstimateTime: ''
        }
      ]
    }
  ]
};

export const groupe_initial_value = {
  schoolData: {
    name: '',
    address: '',
    postalCode: '',
    city: '',
    region: '',
    type: ''
  },
  responsible: [
    {
      responsibleName: '',
      responsibleFirstName: '',
      responsibleFunction: '',
      responsibleEmail: '',
      responsibleTelephone: '',
      responsibleMissionFuture: '',
      groupe: [
        {
          groupeDescription: '',
          groupeNumbers: '',
          groupeVisitor: '',
          groupeVisitDay: '',
          groupeLunchSite: '',
          groupeEstimateTime: ''
        }
      ]
    }
  ]
};

export const digital_initial_value = {
  name: '',
  address: '',
  postalCode: '',
  city: '',
  region: '',
  academy: '',
  type: '',
  responsibleFirstName: '',
  responsibleLastName: '',
  responsibleFunction: '',
  responsibleEmail: '',
  responsibleTelephone: ''
};
