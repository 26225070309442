import React, { useEffect, useState } from 'react';
import { info_icon, down_arrow, up_arrow, cross_icon, blue_cross_icon } from '../../assets/icons';

const Accordian = ({
  info,
  setOpen = false,
  title,
  keyData,
  children,
  clickHandler,
  toolTip,
  isClose,
  removeClassData,
  removeResponsibleData
}) => {
  const [isOpen, setIsOpen] = useState(setOpen);
  useEffect(() => {
    setIsOpen(setOpen);
  }, [setOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    clickHandler();
  };

  const closeResponsible = (ind) => {
    removeResponsibleData(ind);
  };

  const closeClass = (ind) => {
    removeClassData(ind);
  };

  return (
    <>
      <div className="flex cursor-pointer custom-tooltip mb-2 items-center">
        {info && (
          <>
            {isClose && (
              <div
                className="z-10"
                onClick={() => {
                  closeResponsible(keyData);
                }}>
                <div className="flex w-[24px] mx-2">{cross_icon}</div>
              </div>
            )}
            <div className="mr-2" data-te-toggle="tooltip" data-title={toolTip}>
              {info_icon}
            </div>
          </>
        )}
        <div
          onClick={() => handleClick()}
          className="w-full h-[30px] rounded-md items-center flex justify-between bg-[#4b4ced2e] backdrop-blur-[26.5px]">
          <div className="ml-2 uppercase flex items-center">
            {!info && isClose && (
              <div
                className="flex w-[24px] mr-2 z-10"
                onClick={() => {
                  closeClass(keyData);
                }}>
                {blue_cross_icon}
              </div>
            )}
            {title}
          </div>
          <div className="mr-2">{isOpen ? down_arrow : up_arrow}</div>
        </div>
      </div>
      {isOpen && <div>{children}</div>}
    </>
  );
};

export default Accordian;
