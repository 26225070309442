import React, { useState } from 'react';
import { cross_icon } from '../../assets/icons';

export default function InfoPopup({ isInfo, setIsInfo, children }) {
  const [list] = useState([
    {
      title: `L'art au service des métiers`,
      value: `- Métiers d’art et l’art au service des métiers`
    },
    {
      title: `Les métiers de l'artisanat`,
      value: `- Un savoir-faire particulier pour transformer des produits ou des services`
    },
    {
      title: `Innovation technologique`,
      value: `- Les bonnes pratiques dans les métiers : économie circulaire, recyclage, eau, réduction de l’impacts carbone, énergie, matériaux, éco-conception…`
    },
    {
      title: `Développement durable`,
      value: `- Des métiers faisant intervenir des innovations technologiques`
    },
    {
      title: `Du jardin à l'assiette`,
      value: `- Les métiers au service de notre alimentation`
    },
    {
      title: `La science et la santé`,
      value: `- Les métiers au service de notre santé`
    },
    {
      title: `Fabrication`,
      value: `- La fabrication au cœur des métiers`
    },
    {
      title: `Les couleurs dans les métiers`,
      value: `- L’importance de la couleur dans ces métiers`
    },
    {
      title: `Entretien et réparation`,
      value: `- Entretenir et réparer pour éviter le gaspillage`
    },
    {
      title: `Mixité dans les métiers`,
      value: `(avec la Fondation JAE)`
    }
  ]);
  const onCloseClick = () => {
    setIsInfo(false);
  };
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="p-4 bg-white w-full lg:w-[1000px] flex flex-col items-center rounded shadow-lg">
        <button
          onClick={() => onCloseClick()}
          className="bg-cblue-500 p-3 w-[64px] rounded-full w-[84px] text-white self-start">
          {cross_icon}
        </button>
        <div className="mx-0 sm:mx-10 p-4 bg-[#F5F5F5] rounded-md text-[#3130ED] overflow-scroll max-h-[75vh]">
          <div className="text-center sm:text-left flex text-lg sm:text-[1.5rem] md:text-3xl border-b w-full md:w-fit border-[#3130ED] mb-5 sm:mb-10 items-center">
            <h3 className="pt-2 sm:tracking-[2px] bold">
              PARCOURS ACCOMPAGNÉS -
              <span className="font-wskf text-xl sm:text-3xl lg:text-5xl tracking-[3px] uppercase ml-3">
                information
              </span>
            </h3>
          </div>
          <div className="text-[#8182F2] mb-5 sm:mb-10">
            <div className="text-lg sm:text-2xl mb-5 sm:mb-10">
              Plus bas sur la page d’enregistrement, vous avez la possibilité de réserver un
              parcours thématique d’une durée de 1h15 jeudi et vendredi.
            </div>
            <div className="text-md sm:text-lg">
              L’itinéraire proposé est accompagné par un guide qui vous conduira sur les différents
              espaces métiers et à la rencontre de professionnels. Tous les parcours accompagnés se
              feront par groupe par groupes d'une quinzaine de personnes – tout groupe supérieur à
              17 participants sera automatiquement divisé en 2.
            </div>
          </div>
          <div>
            <div className="text-lg bold mb-4">LES PARCOURS PROPOSÉS</div>
            <div className="bg-[#4b4ced2e] rounded-lg p-3">
              {list.map((item) => {
                return (
                  <div className="flex last:border-0 border-b border-[#3130ed4f] mb-2">
                    <p className="text-sm !pb-1">
                      <b className="text-lg mr-1">{item.title}</b>
                      {item.value}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
