import React from 'react';
import { useNavigate } from 'react-router-dom';

import { navigate_back_white } from '../../assets/icons';
import { navigate_back_purple } from '../../assets/icons';
const NavigateBackButton = ({ color, isNavigate = true, onBackClickHandler }) => {
  const navigate = useNavigate();
  const navigateClickHandler = () => {
    isNavigate ? navigate(-1) : onBackClickHandler();
  };
  return (
    <div className="flex justify-start w-full mb-8 mt-8 sm:my-10">
      <button
        className="w-[55px] h-[55px] ml-0 lg:ml-[-5px]"
        onClick={() => navigateClickHandler()}>
        {color === 'white' ? navigate_back_white : navigate_back_purple}
      </button>
    </div>
  );
};

export default NavigateBackButton;
