export const down_triangle_white = (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 9.21582L0.138783 0.215821L14.8612 0.215822L7.5 9.21582Z" fill="white" />
  </svg>
);

export const navigate_back_white = (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37.5" cy="37.5" r="37.5" fill="white" />
    <path
      d="M19.625 38C19.625 47.856 27.644 55.875 37.5 55.875C47.356 55.875 55.375 47.856 55.375 38C55.375 28.144 47.356 20.125 37.5 20.125C27.644 20.125 19.625 28.144 19.625 38ZM22.375 38C22.375 29.6318 29.1318 22.875 37.5 22.875C45.8682 22.875 52.625 29.6318 52.625 38C52.625 46.3682 45.8682 53.125 37.5 53.125C29.1318 53.125 22.375 46.3682 22.375 38ZM27.875 38L33.375 43.5L33.375 39.375L47.125 39.375L47.125 36.625L33.375 36.625L33.375 32.5L27.875 38Z"
      fill="#4B4CED"
    />
  </svg>
);

export const navigate_back_purple = (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37.5" cy="37.5" r="37.5" fill="#4B4CED" />
    <path
      d="M19.625 38C19.625 47.856 27.644 55.875 37.5 55.875C47.356 55.875 55.375 47.856 55.375 38C55.375 28.144 47.356 20.125 37.5 20.125C27.644 20.125 19.625 28.144 19.625 38ZM22.375 38C22.375 29.6318 29.1318 22.875 37.5 22.875C45.8682 22.875 52.625 29.6318 52.625 38C52.625 46.3682 45.8682 53.125 37.5 53.125C29.1318 53.125 22.375 46.3682 22.375 38ZM27.875 38L33.375 43.5L33.375 39.375L47.125 39.375L47.125 36.625L33.375 36.625L33.375 32.5L27.875 38Z"
      fill="white"
    />
  </svg>
);

export const select_Icon = (
  <svg width="12" height="18" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 2L13.5622 9.5L1.43782 9.5L7.5 2Z" fill="#3130ED" />
    <path d="M7.5 23L1.43782 14.75L13.5622 14.75L7.5 23Z" fill="#3130ED" />
  </svg>
);

export const down_arrow = (
  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 12L0.839746 2.76017e-07L18.1603 1.79022e-06L9.5 12Z" fill="#3130ED" />
  </svg>
);

export const up_arrow = (
  <svg width="19" height="12" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 0.5L0.169873 6.5L8.83013 6.5L4.5 0.5Z" fill="#3130ED" />
  </svg>
);

export const info_icon = (
  <svg width="30" height="30" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3.5625C10.712 3.5625 3.96875 10.488 3.96875 19C3.96875 27.512 10.712 34.4375 19 34.4375C27.288 34.4375 34.0312 27.512 34.0312 19C34.0312 10.488 27.288 3.5625 19 3.5625ZM19 5.9375C26.0369 5.9375 31.7188 11.773 31.7188 19C31.7188 26.2271 26.0369 32.0625 19 32.0625C11.9631 32.0625 6.28125 26.2271 6.28125 19C6.28125 11.773 11.9631 5.9375 19 5.9375ZM17.8438 11.875V14.25H20.1562V11.875H17.8438ZM17.8438 16.625V26.125H20.1562V16.625H17.8438Z"
      fill="#4B4CED"
    />
  </svg>
);

export const plus_icon = (
  <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 1V12M12.5 12V23M12.5 12H23.5M12.5 12H1.5"
      stroke="#3130ED"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export const cross_icon = (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="26" cy="26" r="26" fill="#4B4CED" />
    <path
      d="M16.178 35.2449L35.2446 16.1782M35.2446 35.2449L16.178 16.1782"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);

export const check_icon = (
  <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.97485 10.5713L7.95386 16.0356L22.0251 1.96436" stroke="white" strokeWidth="3.5" />
  </svg>
);

export const fb_icon = (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>
  </svg>
);

export const linkedin_icon = (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>
    <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>
  </svg>
);

export const twitter_icon = (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74"></path>
  </svg>
);

export const mobile_nav_icon = (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <rect y="3" width="20" height="2"></rect>
    <rect y="9" width="20" height="2"></rect>
    <rect y="9" width="20" height="2"></rect>
    <rect y="15" width="20" height="2"></rect>
  </svg>
);

export const close_icon = (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <polygon
      fill="#ffc4b0"
      points="24,1.4 22.6,0 12,10.6 1.4,0 0,1.4 10.6,12 0,22.6 1.4,24 12,13.4 22.6,24 24,22.6 13.4,12"></polygon>
  </svg>
);

export const blue_cross_icon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="white" />
    <path
      d="M9.95581 21.6889L21.6891 9.95557M21.6891 21.6889L9.95581 9.95557"
      stroke="#4B4CED"
      strokeWidth="3"
    />
  </svg>
);
