import React, { useState } from 'react';
import { cross_icon } from '../../assets/icons';

export default function TakenPopup({ isAlert, alertData, setIsAlert }) {
  const onCloseClick = () => {
    setIsAlert(false);
  };
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="p-4 bg-white w-full lg:w-[800px] flex flex-col items-center rounded-md shadow-lg">
        <button
          onClick={() => onCloseClick()}
          className="bg-cblue-500 p-3 w-[64px] w-[84px] rounded-full text-white self-start">
          {cross_icon}
        </button>
        <div className="w-[80%] p-4 text-center rounded-md text-[#3130ED] overflow-scroll max-h-[75vh]">
          <div>
            <div className="px-28">
              <div className="text-3xl">ALREADY TAKEN</div>
              <div className="w-full pt-4 mb-9 border-b-[1px] border-[#3130ED] block"></div>
            </div>
            <div>
              <div className="p-8 rounded-lg flex justify-center items-center mx-16">
                <div className="">
                  <div className="flex mb-3">
                    <div className="bg-[#3130ed29] px-8 py-1 rounded-md mr-3">MÉTIER :</div>
                    <div>{alertData.label}</div>
                  </div>
                  <div className="flex mb-3">
                    <div className="bg-[#3130ed29] px-8 py-1 rounded-md mr-3">DATE :</div>
                    <div>{alertData.selectedFullDay}</div>
                  </div>
                  <div className="flex">
                    <div className="bg-[#3130ed29] px-8 py-1 rounded-md mr-3">HEURE :</div>
                    <div>{alertData.time} (1h30 session)</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 px-10">
              <p>
                Unfortunately, this time slot has just been booked. Please select another time slot
                for your class.
              </p>
            </div>
            <div className="mt-5">
              <button
                onClick={() => onCloseClick()}
                className="bg-[#3130ED] px-7 py-1 text-white rounded-full">
                Re-select time slot
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
