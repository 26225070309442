import React, { useEffect, useState } from 'react';
import Radio from './form/Radio';

const TimeSlot = ({ title, subTitle, timeSlot, onSelectHandler, listSlotes, currentSlot = '' }) => {
  const [activeSlot, setActiveSlot] = useState('');
  const [listSlots, setLists] = useState([]);
  const [displayProfessions, setProfessions] = useState([]);
  const [activeDate, setActiveDate] = useState('');
  const [activeType, setActiveType] = useState('');

  const onSelectSlot = (slot, label, val) => {
    const selectedValues = {
      date: activeDate,
      type: activeType,
      time: slot.time,
      slot: val,
      selectedFullDay: `${activeDate} Septembre 2023 (${activeType})`,
      label: label
    };
    onSelectHandler(selectedValues);
  };
  useEffect(() => {
    setActiveSlot(`${listSlotes[0].date} ${listSlotes[0].type}`);
    setLists(listSlotes);
    setProfessions(listSlotes[0].professions);
    setActiveDate(listSlotes[0].date);
    setActiveType(listSlotes[0].type);
  }, []);

  const onClickSlots = (date, type) => {
    setActiveSlot(`${date} ${type}`);
    const professions = listSlots.filter((item) => {
      if (item.date === date && item.type === type) {
        return item;
      }
    });
    setProfessions(professions[0].professions);
    setActiveDate(professions[0].date);
    setActiveType(professions[0].type);
  };

  return (
    <>
      <div className="mb-4">
        <div className="text-lg">
          <b>{title}</b>
        </div>
        <div className="text-base">{subTitle}</div>
      </div>
      <div>
        <div className="flex">
          {listSlots.map((item, inde) => {
            return activeSlot === `${item.date} ${item.type}` ? (
              <div
                key={inde}
                className="border border-[#3130ED] bg-[#4b4ced2e] rounded-t-md p-3 h-[100px] w-[105px] flex-col items-center justify-center cursor-pointer"
                onClick={() => onClickSlots(item.date, item.type)}>
                <b className="text-2xl flex justify-center">{item.date}</b>
                <div className="!p-0 text-sm flex justify-center text-center">
                  <b>{item.type}</b>
                </div>
              </div>
            ) : (
              <div
                key={inde}
                className="p-3 h-[100px] w-[105px] flex-col items-center justify-center cursor-pointer"
                onClick={() => onClickSlots(item.date, item.type)}>
                <b className="text-2xl flex justify-center">{item.date}</b>
                <div className="!p-0 text-sm flex justify-center text-center">
                  <b>{item.type}</b>
                </div>
              </div>
            );
          })}
        </div>
        <div className="px-14 sm:px-36 md:px-48 lg:px-3 bg-[#4b4ced2e]">
          {displayProfessions.map((data, inde) => {
            return (
              <div
                key={inde}
                className="pt-6 pb-7 lg:pb-2 flex items-center justify-center last:border-b-0 border-b border-[#3130ed4f]">
                <div className="w-full">
                  <div className="text-base	lg:text-xl mb-2 w-full flex justify-center intems-center">
                    {data.title}
                  </div>
                  <div className="bg-[#ffffff75] py-3 px-2 rounded-md block lg:flex justify-center gap-8">
                    {data.slots.map((item, key) => {
                      return (
                        <div key={key} className="flex last:mb-0 mb-5 lg:mb-0 justify-center mx-2">
                          <div className="w-32 lg:w-auto rounded-md bg-[#4b4ced2e] text-xs md:text-sm flex items-center justify-center px-2 mr-1 lg:mr-1">
                            {item.time}
                          </div>
                          <div className="flex">
                            {item.slot.map((st, key) => {
                              return (
                                <Radio
                                  extraClass={`!mr-0 !mb-0`}
                                  isBooked={st.isBooked}
                                  currentSlot={currentSlot === st._id}
                                  key={key}
                                  data={{
                                    label: ['', '', ''],
                                    name: 'time',
                                    value: st.type,
                                    disabled: st.isBooked,
                                    onChange: (val) =>
                                      onSelectSlot(item, data.title, val.target.defaultValue)
                                  }}
                                  theme={'white'}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TimeSlot;
