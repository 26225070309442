import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { downloadExcel } from 'react-export-table-to-excel';

import { getDigitalDataAPI } from '../../services/index';

export default function Table() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [columns] = useState([
    'Académie de l’établissement',
    "Région de l'établissement",
    'Nom de l’établissement',
    'Adresse de l’établissement',
    'Code postal de l’établissement',
    'Ville de l’établissement',
    'Type de l’établissement',
    "Autres : Spécifier le type d'établissement",
    'Prénom',
    'Nom',
    'email',
    'Téléphone',
    'function',
    'Nombre approximatif d’élèves prévus',
    'Interview visio avec un CHAMPION',
    'Date souhaitée Interview'
  ]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { data } = await getDigitalDataAPI(id);
      if (data.length === 0) toast.error('Nothing is added so far');
      setData(data);
      const csvTableData = data.map((item) => [
        item.academie_establishment,
        item.region_establishment,
        item.nom_establishment,
        item.addresse_establishment,
        item.code_establishment,
        item.ville_establishment,
        item.type_establishment,
        item.type_establishment_other,
        item.lastName,
        item.firstName,
        item.email,
        item.telephone,
        item.function,
        item.nombre_approximate,
        item.interview,
        item.interview_day
      ]);
      setCSVData([...csvTableData]);
      setLoading(false);
    } catch (e) {
      const message = e.response?.data || 'Something Went Wrong';
      toast.error(message);
    }
  };

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'compiled-data',
      tablePayload: {
        header: columns,
        body: csvData
      }
    });
  }

  return (
    <div className={`pt-20 md:pt-[200px] bg-white min-h-screen`}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {!loading && (
                <div className="">
                  <CSVLink
                    data={[columns, ...csvData]}
                    filename="compiled-data.csv"
                    className="bg-lyon-purple px-3 py-2 rounded-md mr-3">
                    Download CSV
                  </CSVLink>
                  <button
                    onClick={handleDownloadExcel}
                    className="bg-lyon-purple px-3 py-1.5 rounded-md">
                    Download Excel
                  </button>
                </div>
              )}
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.map((item) => (
                    <tr key={item.email}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.academie_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.region_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.nom_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.addresse_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.code_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.ville_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.type_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.comptez_vous}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.firstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.telephone}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.function}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.nombre_approximate}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.interview}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.interview_day}
                      </td>
                    </tr>
                  ))}
                  {data.length < 10 &&
                    [...new Array(10 - data.length)].map(() => (
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
