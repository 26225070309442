export default [
  '09h00 - 09h30',
  '09h30 - 10h00',
  '10h00 - 10h30',
  '10h30 - 11h00',
  '11h00 - 11h30',
  '11h30 - 12h00',
  '12h00 - 12h30',
  '12h30 - 13h00',
  '13h00 - 13h30',
  '13h30 - 14h00',
  '14h00 - 14h30',
  '14h30 - 15h00',
  '15h00 - 15h30'
];
