import React from 'react';

import logos from '../../assets/images/footer/logos.png';
import wsf_logo from '../../assets/images/header/wsf-logo.png';
import {
  fb_icon,
  flickr_icon,
  insta_icon,
  linkedin_icon,
  yt_icon,
  twitter_icon,
  tiktok_icon,
  twitch_icon
} from './live-icons';

const Footer = ({ headerType }) => {
  if (headerType === 'simple')
    return (
      <footer className={`bg-lyon-blue w-[99%] ml-[0.5%]`}>
        <div className="outer-container">
          <span className="block bg-white w-full md:mb-8 h-[26px]"></span>
          <div className={`container flex mx-auto`}>
            <div className={`menu-container py-4 w-full`}>
              {/* logos */}
              <img src={logos} alt={'logos'} className="w-full block h-auto" />
            </div>
          </div>
        </div>
      </footer>
    );
  else
    return (
      <footer className={`bg-live-blue`}>
        <div className="outer-container">
          <div className={`container flex mx-auto`}>
            <div className={`menu-container py-4 pb-20 w-full grid grid-cols-1 md:grid-cols-3`}>
              <div className="p-6">
                <img src={wsf_logo} alt="WorldSkills France" className="block w-[160px]" />
                <p className="text-white text-sm mt-2">
                  © 2023. All rights reserved. Powered by
                  <br />
                  <a
                    href="https://www.worldskills-france.org/"
                    target="_blank"
                    rel={'noreferrer'}
                    className="text-white hover:text-live-cream">
                    worldskills-france
                  </a>
                  .
                </p>
              </div>
              <div className="p-6">
                <h4 className="block text-white text-4xl font-wskf tracking-[3px] font-bold">
                  Contact
                </h4>
                <span className="block my-4 h-[1px] bg-[#ffffff33] mb-4"></span>
                <p className="block text-white text-base">
                  WorldSkills France <br />
                  33 rue de Naples, 75008 PARIS
                </p>
              </div>
              <div className="p-6">
                <h4 className="block text-white text-4xl font-wskf tracking-[3px] font-bold">
                  Suivez-nous{' '}
                  <span className="text-2xl font-euclid uppercase font-normal">
                    sur les réseaux sociaux
                  </span>
                </h4>
                <span className="block my-4 h-[1px] bg-[#ffffff33] mb-4"></span>
                <div className="grid grid-cols-2">
                  <div className='relative after:content-[""] after:absolute after:right-0 after:top-0 after:h-[95%] after:w-[0.5px] after:bg-[#ffffff33]'>
                    <a
                      href="https://www.facebook.com/WorldSkillsFrance"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {fb_icon} Facebook
                    </a>
                    <a
                      href="https://www.flickr.com/photos/worldskillsfrance/collections/"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {flickr_icon} Flickr
                    </a>
                    <a
                      href="https://www.instagram.com/worldskillsfrance/"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {insta_icon} Instagram
                    </a>
                    <a
                      href="https://www.linkedin.com/company/worldskills-france---cofom"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {linkedin_icon} LinkedIn
                    </a>
                  </div>
                  <div className="pl-10">
                    <a
                      href="https://www.youtube.com/user/cofom1"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {yt_icon} Youtube
                    </a>
                    <a
                      href="https://twitter.com/WorldSkillsFR"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {twitter_icon} Twitter
                    </a>
                    <a
                      href="https://www.tiktok.com/@worldskillsfr"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {tiktok_icon} Tik Tok
                    </a>
                    <a
                      href="https://www.twitch.tv/worldskillsfrance"
                      className="flex gap-2 text-white text-base hover:text-live-cream mb-2">
                      {twitch_icon} Twitch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;
