import * as Yup from 'yup';

export const form_2_schema = Yup.object().shape({
  nom_establishment: Yup.string().required('required'),
  addresse_establishment: Yup.string().required('required'),
  code_establishment: Yup.string().required('required').max(8, 'Code Trop long'),
  ville_establishment: Yup.string().required('required'),
  region_establishment: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('required'),
  type_establishment: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('required'),
  type_establishment_other: Yup.string(),
  academie_establishment: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('required'),
  nombre_approximate: Yup.number().required('required'),
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  function: Yup.string().required('required'),
  email: Yup.string().email('Nous ne reconaisson pas cette adresse e-mail').required('required'),
  telephone: Yup.string().required('required'),
  interview: Yup.bool().oneOf([true, false]),
  interview_day: Yup.string()
});

export const form_2_initial_values = {
  nom_establishment: '',
  addresse_establishment: '',
  code_establishment: '',
  ville_establishment: '',
  region_establishment: '',
  type_establishment: '',
  type_establishment_other: '',
  academie_establishment: '',
  nombre_approximate: '',
  firstName: '',
  lastName: '',
  function: '',
  email: '',
  telephone: '',
  interview: false,
  interview_day: ''
};
