import React, { useState } from 'react';
import { cross_icon } from '../../assets/icons';

export default function ConfirmPopup({ isConfirm, setIsConfirm, data, confirmSlot }) {
  const onCloseClick = () => {
    setIsConfirm(false);
  };

  const onConfirmClick = () => {
    confirmSlot();
  };
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="p-4 bg-white w-full lg:w-[800px] flex flex-col items-center rounded-md shadow-lg">
        <button
          onClick={() => onCloseClick()}
          className="bg-cblue-500 p-3 w-[64px] rounded-full text-white self-start">
          {cross_icon}
        </button>
        <div className="w-[80%] p-4 text-center rounded-md text-[#3130ED] overflow-scroll max-h-[75vh]">
          <div>
            <div className="text-4xl ">CONFIRMEZ VOTRE RÉSERVATION</div>
            <div className="w-full pt-4 mb-9 border-b-[1px] border-[#3130ED] block"></div>
            <div>
              <div className="p-8 bg-[#3130ed29] rounded-lg flex justify-center items-center mx-8">
                <div className="">
                  <div className="flex mb-3">
                    <div className="bg-[#f0f8ff] px-8 py-1 rounded-md mr-3">MÉTIER :</div>
                    <div>{data.label}</div>
                  </div>
                  <div className="flex mb-3">
                    <div className="bg-[#f0f8ff] px-8 py-1 rounded-md mr-3">DATE :</div>
                    <div>{data.selectedFullDay}</div>
                  </div>
                  <div className="flex">
                    <div className="bg-[#f0f8ff] px-8 py-1 rounded-md mr-3">HEURE :</div>
                    <div>{data.time} (1h30 session)</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                onClick={onConfirmClick}
                className="bg-[#3130ED] px-7 py-1 text-white rounded-full">
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
