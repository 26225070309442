import axios from 'axios';

const URL = process.env.REACT_APP_BASE_URL;

export const getInPersonDataAPI = (id) => {
  return axios.get(`${URL}/public/lyon-in-person-response-by-academy?id=${id}`);
};

export const getDigitalDataAPI = (id) => {
  return axios.get(`${URL}/public/lyon-digital-response-by-academy?id=${id}`);
};

export const getTimeSlots = () => {
  return axios.get(`${URL}/lyon/time-slots`);
};

export const getRegions = (organization = '650bd89479ba3ae92524955b') => {
  return axios.get(URL + `/public/regions`, { params: { organization } });
};

export const getAcademies = () => {
  return axios.get(URL + `/lyon/academies`);
};

export const getScholarsData = () => {
  return axios.get(URL + `/lyon/scolars-form-data`);
};
export const getOthersData = () => {
  return axios.get(URL + `/lyon/others-form-data`);
};
export const getDigitalData = () => {
  return axios.get(URL + `/lyon/digital-form-data`);
};
export const getAcademyData = (id) => {
  return axios.get(URL + `/lyon/get-academy-data?id=${id}`);
};
export const getLyonForm1API = (id) => {
  return axios.get(URL + `/lyon/get-lyon-form1?id=${id}`);
};

export const updateSelectSlot = (body) => {
  return axios.post(URL + `/lyon/set-select-slot`, body);
};

export const updateScolarFormAPI = (body) => {
  return axios.post(URL + `/lyon/update-scolar-form`, body);
};

export const deleteScholarById = (body) => {
  return axios.post(URL + `/lyon/delete-scholar`, body);
};

export const updateOtherFormAPI = (body) => {
  return axios.post(URL + `/lyon/update-other-form`, body);
};

export const deleteOtherById = (body) => {
  return axios.post(URL + `/lyon/delete-other`, body);
};

export const checkRegisteredTeacher = (params) => {
  let postURL = 'https://develop.psyfiers.ch/core/user/exists';
  return axios.get(
    postURL,
    { params },
    {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }
  );
};

export const getSlotsWithBookingsAPI = () => {
  return axios.get(URL + `/lyon/slots-with-bookings`);
};

export const registerTeacher = (body) => {
  let postURL = 'https://develop.psyfiers.ch/core/security/register?path=mission-future-eleves';
  return axios.post(postURL, body, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};
