import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Input from '../../components/form/Input';
import { getTimeSlots, getScholarsData, updateSelectSlot } from '../../services';
import TakenPopup from '../../components/popups/takenPopup';
import RegisterCollage from '../Register/registerCollage';
import ResponsePopup from '../../components/popups/responsePopup';
import RegisterEstablish from '../Register/registerEstablish';
import Loader from '../../components/common/loader';

const SelectSlot = ({}) => {
  // const [data, setData] = useState([]);
  const [isSlot, setIsSlot] = useState(false);
  const [isCollage, setIsCollage] = useState(false);
  const [listSlotes, setListSlots] = useState([]);
  const [isSuccess, setSuccess] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertData, setAlertData] = useState({});

  const [isLoading, setLoading] = useState(true);
  const [displayUser, setDisplayUser] = useState([]);
  const [selecedUser, setSelectedUser] = useState('');
  const [displayResponsible, setDisplayResponsible] = useState([]);
  const [selectResponsible, setSelectResponsible] = useState('');
  const [displayClasses, setDisplayClasses] = useState([]);
  const [selectClass, setSelectClass] = useState('');

  useEffect(() => {
    getPageData();
    setSlotsList();
  }, []);

  // getting the list of slots from BE
  const setSlotsList = async () => {
    try {
      const { data } = await getTimeSlots();
      if (data.length) {
        setListSlots(data);
      } else {
        toast.error('Quelque chose a mal tourné');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  // set the seleced slot data in form according to responsible and classes
  const setSlotDataInForm = async (value) => {
    let upDateList = listSlotes;
    upDateList = upDateList.map((item) => {
      if (item.date === value.date && item.type === value.type) {
        item.professions.map((prof) => {
          if (prof.title === value.label) {
            prof.slots.map((ti) => {
              if (ti.time === value.time) {
                ti.slot.map((st) => {
                  if (st.type === value.slot) {
                    st.isBooked = true;
                  }
                  return st;
                });
              }
              return ti;
            });
          }
          return prof;
        });
      }
      return item;
    });
    submitDataAPI(value);
  };

  const submitDataAPI = async (selectValue) => {
    try {
      setLoading(true);
      let reqData = {
        data: {
          userId: selecedUser._id,
          responsibleId: selectResponsible._id,
          classId: selectClass._id,
          selectedSlot: selectValue
        }
      };
      await updateSelectSlot(reqData);
      toast.success('créneau réservé avec succès');
      selectClass.isSlot = true;
      setSelectClass('');
    } catch (e) {
      if (e?.response?.status === 409) {
        setAlertData(e.response.data.slot);
        setIsAlert(true);
        toast.error(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleBackClick = () => {
    setIsSlot(false);
    setIsCollage(false);
  };

  const handleNavigate = () => {
    setIsSlot(false);
    setIsCollage(true);
  };

  // close alert popup
  const handleAlert = () => {
    setIsAlert(false);
  };

  const getPageData = async () => {
    try {
      const { data } = await getScholarsData();
      const filterUserData = await filterUser(data);
      setDisplayUser(filterUserData);
      toast.success('Enregistrement réussi ! Merci !');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting the form');
    } finally {
      setLoading(false);
    }
  };

  const filterUser = async (resData) => {
    resData.map((item) => {
      item['label'] = item.name;
      item['value'] = item._id;
    });
    return resData;
  };

  const onSelectUserHandler = async (value) => {
    setSelectedUser(value);
    value.responsible.map((item) => {
      item['label'] = item.responsibleFirstName;
      item['value'] = item._id;
    });
    setDisplayResponsible(value.responsible);
    setSelectResponsible('');
    setSelectClass('');
  };

  const onSelectResponsibleHandler = async (value) => {
    setSelectResponsible(value);
    value.classes.map((item) => {
      item['label'] = item.classNumbers;
      item['value'] = item._id;
      item['disabled'] = item?.selectedSlot ? true : false;
      item['isSlot'] = item?.selectedSlot ? true : false;
    });
    setDisplayClasses(value.classes);
    setSelectClass('');
  };

  const onSelectClassHandler = async (value) => {
    setSelectClass(value);
  };

  const onSelectSlotClickHandler = async () => {
    setIsSlot(true);
  };

  return (
    <div>
      {!isSlot && !isCollage && (
        <div className={`pt-20 md:pt-[200px] bg-white min-h-screen text-[#3130ED]`}>
          <div className="flex gap-10 p-4">
            <div className="w-full text-center">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Nom établissement'],
                  input: {
                    options: displayUser,
                    placeholder: 'Sélectionner',
                    name: 'user',
                    value: selecedUser,
                    onChange: (val) => {
                      onSelectUserHandler(val);
                    }
                  }
                }}
              />
            </div>
            <div className="w-full text-center">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Prénom responsable'],
                  input: {
                    options: displayResponsible,
                    placeholder: 'Sélectionner',
                    name: 'user',
                    value: selectResponsible,
                    onChange: (val) => {
                      onSelectResponsibleHandler(val);
                    }
                  }
                }}
              />
            </div>
            <div className="w-full text-center">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Numéro de la classe'],
                  input: {
                    options: displayClasses,
                    placeholder: 'Sélectionner',
                    name: 'user',
                    value: selectClass,
                    onChange: (val) => {
                      onSelectClassHandler(val);
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="mx-10">
            {selectClass.isSlot === false ? (
              <div className="sm:flex gap-4 mt-8">
                <div className="sm:w-[60%] md:w-[70%] lg:w-[50%] mb-2 lg:mb-0">
                  PARCOURS ACCOMPAGNÉ - Je souhaite réserver un Parcours Accompagné pour ma classe
                </div>
                <div>
                  <button
                    onClick={() => {
                      onSelectSlotClickHandler();
                    }}
                    // disabled = {(classData.selectedSlot && classData.selectedSlot.date)}
                    className="border-[#3130ED] rounded-full text-base border-2 border-solid p-2 bg-[#3130ed29]">
                    Cliquez ICI
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
      {isSlot && (
        <RegisterEstablish
          listSlotes={listSlotes}
          isSlot={isSlot}
          setIsSlot={setIsSlot}
          handleNavigate={handleNavigate}
          selectedSlotData={setSlotDataInForm}
          handleBackClick={handleBackClick}
        />
      )}
      {isCollage && (
        <RegisterCollage
          listSlotes={listSlotes}
          isCollage={isCollage}
          setIsCollage={setIsCollage}
          selectedSlotData={setSlotDataInForm}
          handleBackClick={handleBackClick}
        />
      )}
      {isAlert && <TakenPopup isAlert={isAlert} alertData={alertData} setIsAlert={handleAlert} />}
      {isSuccess && (
        <ResponsePopup
          title={'mERCI POUR VOTRE RÉSERVATION'}
          image={'/images/thankyou.png'}
          subText={`Finalisez votre enregistrement et validez-le à la fin du formulaire.`}
          buttonText={`RETOURTNER À L’ENREGISTREMENT`}
          isCloseButton={false}
          setSuccess={setSuccess}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default SelectSlot;
