import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { mobile_nav_icon, close_icon } from '../../assets/icons';
import wsf_logo from '../../assets/images/header/wsf-logo.png';

const Header = ({ headerType }) => {
  const [hideButton, setButtonStatus] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';

      const headerSimple = document.querySelector('.header-section');
      if (headerSimple) {
        if (direction === 'up') {
          headerSimple.classList.add('animate-slideDown');
          headerSimple.classList.remove('animate-slideUp');
        } else {
          headerSimple.classList.add('animate-slideUp');
          headerSimple.classList.remove('animate-slideDown');
        }
      }

      const headerLive = document.querySelector('.header-section-live');
      if (headerLive) {
        if (scrollY > 40) {
          headerLive.classList.add('animate-slideDownLive');
          headerLive.classList.add('fixed');
          headerLive.classList.add('top-0');
          headerLive.classList.add('left-0');
        } else {
          headerLive.classList.remove('animate-slideDownLive');
          headerLive.classList.remove('fixed');
          headerLive.classList.remove('top-0');
          headerLive.classList.remove('left-0');
        }
      }

      // update
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, []);

  useEffect(() => {
    setButtonStatus(
      window.location.href.includes('digital') || window.location.href.includes('in-person')
    );
  }, [location]);

  if (headerType === 'simple')
    return (
      <>
        <header
          className={`header-section bg-lyon-blue fixed top-0 left-[0.5%] w-[99%] z-10 fill-mode-forwards`}>
          <div className="outer-container">
            <div className={`container flex mx-auto`}>
              <div
                className={`menu-container pt-3 md:py-5 md:pt-8 flex flex-row justify-center md:justify-between w-full md:border-b-2 md:border-b-white`}>
                <NavLink to={'/'}>
                  <p className="text-xl text-white uppercase">
                    47e Finale{' '}
                    <span className="font-wskf text-2xl tracking-[2px]">NaTIonaLe WoRLDSKILLs</span>{' '}
                    à Lyon
                    <span className="text-sm block">14 - 16 septembre 2023</span>
                  </p>
                </NavLink>
                {!hideButton && (
                  <div className={`items-center gap-2 hidden md:flex`}>
                    <HeaderLink URL="/form_1" text={'InscRIpTIon OpTIon 1'} />
                    <HeaderLink URL="/form_2" text={'InscRIpTIon OpTIon 2'} />
                  </div>
                )}
              </div>
            </div>
            <span className="block bg-white w-full md:mt-8 h-[26px]"></span>
          </div>
        </header>
      </>
    );
  else
    return (
      <>
        {/* Top Link */}
        <div className="bg-live-purple hidden lg:block">
          <div
            className={`flex flex-wrap mx-auto menu-container p-2 flex flex-row justify-center w-full`}>
            <a
              href="https://competition-nationale-des-metiers.org/"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream">
              Accueil
            </a>
            <a
              href="https://competition-nationale-des-metiers.org/levenement"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              L’évènement
            </a>
            <a
              href="https://competition-nationale-des-metiers.org/le-programme"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Le programme
            </a>
            <a
              href="https://competition-nationale-des-metiers.org/inscription"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Inscription
            </a>
            <a
              href="https://www.inscriptions-groupes.competition-nationale-des-metiers.org/"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Groupes
            </a>
            <a
              href="https://competition-nationale-des-metiers.org/preparer-ma-visite"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Préparer ma visite
            </a>
            <a
              href="https://competition-nationale-des-metiers.org/partenaires-worldskills-france"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Partenaires
            </a>
            <a
              href="https://competition-nationale-des-metiers.org/medias-worldskills-france"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Médias
            </a>
            <a
              href="https://www.equipes-regionales.competition-nationale-des-metiers.org/"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Equipes régionales
            </a>
            <a
              href="https://www.worldskills-france.org/"
              className="font-euclid font-normal text-white text-sm inline-block mr-2 hover:text-live-cream before:content-[''] before:h-[0.9em] before:ml-2 before:mr-4 before:border-l-[1px] before:border-[#ffffff33]">
              Worldskills France
            </a>
          </div>
        </div>
        {/* Main Header with logo */}
        <header className={`header-section-live bg-live-blue w-[100%] z-10 fill-mode-forwards`}>
          <div className="outer-container">
            <div className={`container flex mx-auto`}>
              <div
                className={`menu-container px-4 py-4 lg:px-0 flex flex-row flex-row-reverse lg:flex-row justify-between items-center md:justify-between w-full`}>
                <div className="lg:hidden w-[50px]"></div>
                <div className="flex gap-12 items-center justify-between">
                  <a
                    href="https://competition-nationale-des-metiers.org/"
                    className="w-[90px] md:w-[144px]">
                    <img src={wsf_logo} alt="WorldSkills France" />
                  </a>
                  <div className="live-competition-links hidden lg:block ">
                    <a
                      href="https://competition-nationale-des-metiers.org/#haut"
                      className="font-wskf text-[26px] text-white mr-8 hover:text-live-cream">
                      Dates et lieu
                    </a>
                    <a
                      href="https://competition-nationale-des-metiers.org/#countdown"
                      className="font-wskf text-[26px] text-white mr-8 hover:text-live-cream">
                      Compte à rebours
                    </a>
                    <a
                      href="https://competition-nationale-des-metiers.org/#chiffres"
                      className="font-wskf text-[26px] text-white mr-8 hover:text-live-cream">
                      en chiffres
                    </a>
                    <a
                      href="https://competition-nationale-des-metiers.org/#public"
                      className="font-wskf text-[26px] text-white mr-8 hover:text-live-cream">
                      Publics
                    </a>
                    <a
                      href="https://competition-nationale-des-metiers.org/#actualite"
                      className="font-wskf text-[26px] text-white mr-8 hover:text-live-cream">
                      Actualités
                    </a>
                    <a
                      href="https://competition-nationale-des-metiers.org/#volontaire"
                      className="font-wskf text-[26px] text-white mr-8 hover:text-live-cream">
                      Volontaires
                    </a>
                    <a
                      href="https://competition-nationale-des-metiers.org/#jeu"
                      className="font-wskf text-[26px] text-white hover:text-live-cream">
                      Jeu de piste
                    </a>
                  </div>
                </div>
                <button
                  className="block lg:hidden open_mobile_nav"
                  onClick={() => setOpenMobileMenu(true)}>
                  {mobile_nav_icon}
                </button>
              </div>
            </div>
          </div>
        </header>
        {/* Mobile menu */}
        {openMobileMenu && (
          <div
            className="fixed top-0 left-0 w-full h-[100vh] z-10 bg-[#001c574d]"
            onClick={() => setOpenMobileMenu(false)}></div>
        )}
        <div
          className={`live-header-popup fixed top-0 left-0 w-[70vw] h-[100vh] bg-live-purple z-20 p-4 pt-12 transition duration-300 ${
            openMobileMenu ? 'translate-x-[0%]' : 'translate-x-[-100%]'
          }`}>
          <button
            className={'absolute top-[10px] right-[10px] lg:right-[30px] header-close-btn'}
            onClick={() => setOpenMobileMenu(false)}>
            {close_icon}
          </button>

          <div className="links">
            <a
              href="https://competition-nationale-des-metiers.org/"
              className="block text-white text-base text-center hover:text-live-cream">
              Accueil
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://competition-nationale-des-metiers.org/levenement"
              className="block text-white text-base text-center hover:text-live-cream">
              L’évènement
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://competition-nationale-des-metiers.org/le-programme"
              className="block text-white text-base text-center hover:text-live-cream">
              Le programme
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://competition-nationale-des-metiers.org/inscription"
              className="block text-white text-base text-center hover:text-live-cream">
              Inscription
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://www.inscriptions-groupes.competition-nationale-des-metiers.org/"
              className="block text-white text-base text-center hover:text-live-cream">
              Groupes
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://competition-nationale-des-metiers.org/preparer-ma-visite"
              className="block text-white text-base text-center hover:text-live-cream">
              Préparer ma visite
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://competition-nationale-des-metiers.org/partenaires-worldskills-france"
              className="block text-white text-base text-center hover:text-live-cream">
              Partenaires
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://competition-nationale-des-metiers.org/medias-worldskills-france"
              className="block text-white text-base text-center hover:text-live-cream">
              Médias
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://www.equipes-regionales.competition-nationale-des-metiers.org/"
              className="block text-white text-base text-center hover:text-live-cream">
              Equipes régionales
            </a>
            <span className="block my-4 h-[1px] bg-[#ffffff33]"></span>
            <a
              href="https://www.worldskills-france.org/"
              className="block text-white text-base text-center hover:text-live-cream">
              Worldskills France
            </a>
          </div>
        </div>
      </>
    );
};

const HeaderLink = ({ URL = '#', text }) => {
  return (
    <NavLink
      to={URL}
      className={({ isActive }) =>
        isActive
          ? 'text-white bg-transparent border-2 border-white rounded-full px-3 py-1 pt-2 tracking-[2px] font-wskf text-xl font-400'
          : 'text-lyon-blue bg-white rounded-full px-3 py-1 pt-2 tracking-[2px] font-wskf text-xl font-400'
      }>
      {text}
    </NavLink>
  );
};

export default Header;
