import axios from 'axios';

const URL = process.env.REACT_APP_BASE_URL;

export const SubmitForm_1 = (body) => {
  return fetch('https://hooks.zapier.com/hooks/catch/12087525/32yd5wt/', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(body)
  });
};

export const SubmitForm_2 = (body) => {
  return fetch('https://hooks.zapier.com/hooks/catch/12087525/326hkrs/', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(body)
  });
};

export const inPersonAPI = (body) => {
  return axios.post(URL + '/public/lyon-2023-in-person', body);
};

export const digitalAPI = (body) => {
  return axios.post(URL + '/public/lyon-2023-digital', body);
};

export const submitDigitalApi = (body) => {
  return axios.post(URL + '/lyon/digital-participant', body);
};

export const submitScolarFormAPI = (body) => {
  return axios.post(URL + '/lyon/scolar-form', body);
};

export const submitOtherFormAPI = (body) => {
  return axios.post(URL + '/lyon/other-form', body);
};
