import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { downloadExcel } from 'react-export-table-to-excel';
import { useParams } from 'react-router-dom';

import { getAcademyData } from '../../services';
const AcademyData = ({}) => {
  const { id } = useParams();
  const [digitalData, setDigital] = useState([]);
  const [scholarData, setScholar] = useState([]);
  const [csvDigitalData, setCSVDigitalData] = useState([]);
  const [csvScholarData, setCSVScholarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [digitalCols] = useState([
    '_id',
    'name',
    'address',
    'postalCode',
    'city',
    'type',
    'region',
    'academy',
    'responsibleLastName',
    'responsibleFirstName',
    'responsibleFunction',
    'responsibleEmail',
    'responsibleTelephone'
  ]);
  const [displayDigitalCols] = useState([
    'ID',
    'Nom établissement',
    'Adresse',
    'Code postal',
    'Ville',
    'Type établissement',
    'Région',
    'Académie',
    'Nom responsable',
    'Prénom responsable',
    'Fonction',
    'E-Mail',
    'Téléphone'
  ]);
  const [scholarCols] = useState([
    '_id',
    'name',
    'address',
    'postalCode',
    'city',
    'typeEstablishment',
    'region',
    'academy',
    'responsibleName',
    'responsibleFirstName',
    'responsibleFunction',
    'responsibleEmail',
    'responsibleTelephone',
    'responsibleMissionFuture',
    'classLevel',
    'classNumbers',
    'classVisitor',
    'classVisitDay',
    'classLunchSite',
    'classEstimateTime',
    'selectedSlot_Date',
    'selectedSlot_Day',
    'selectedSlot_Time',
    'selectedSlot_label'
  ]);

  const [displayScholarCols] = useState([
    'ID',
    'Nom établissement',
    'Adresse',
    'Code postal',
    'Ville',
    'Type établissement',
    'Région',
    'Académie',
    'Nom responsable',
    'Prénom responsable',
    'Fonction',
    'E-Mail',
    'Téléphone',
    'mission future',
    'Niveau de la classe',
    'Numéro de la classe',
    'Nombre visiteurs',
    'Jour de visite',
    'Lunch sur site',
    "Heure d'arrivée",
    'Date parcours accompagné',
    'Jour parcours accompagné',
    'Créneau horaire',
    'Dénomination parcours'
  ]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { data } = await getAcademyData(id);
      setDigital(data.digitalData);
      setDigitalCsvValues(data.digitalData);
      const filteredData = await filterData(data.scolarData);
      setScholar(filteredData);
      setScholarCsvValues(filteredData);
      setLoading(false);
      toast.success('Enregistrement réussi ! Merci !');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting the form');
    } finally {
    }
  };

  const filterData = async (data) => {
    let newData = [];
    data.map((userItems) => {
      userItems.responsible.map((respItems) => {
        respItems.classes.map((classItems) => {
          let rowData = {
            _id: userItems._id,
            name: userItems.name,
            address: userItems.address,
            postalCode: userItems.postalCode,
            city: userItems.city,
            typeEstablishment: userItems.typeEstablishment,
            region: userItems?.region?.name,
            academy: userItems?.academy?.name,
            responsibleName: respItems.responsibleName,
            responsibleFirstName: respItems.responsibleFirstName,
            responsibleFunction: respItems.responsibleFunction,
            responsibleEmail: respItems.responsibleEmail,
            responsibleTelephone: respItems.responsibleTelephone,
            responsibleMissionFuture: respItems.responsibleMissionFuture,
            classLevel: classItems.classLevel,
            classNumbers: classItems.classNumbers,
            classVisitor: classItems.classVisitor,
            classVisitDay: classItems.classVisitDay,
            classLunchSite: classItems.classLunchSite,
            classEstimateTime: classItems.classEstimateTime.value,
            selectedSlot_Date: classItems?.selectedSlot?.date,
            selectedSlot_Day: classItems?.selectedSlot?.type,
            selectedSlot_Time: classItems?.selectedSlot?.time,
            selectedSlot_label: classItems?.selectedSlot?.label
          };
          newData.push(rowData);
        });
      });
    });
    return newData;
  };

  function setDigitalCsvValues(data) {
    const csvTableData = data.map((item) => [
      item._id,
      item.name,
      item.address,
      item.postalCode,
      item.city,
      item.type,
      item.region,
      item.academy,
      item.responsibleLastName,
      item.responsibleFirstName,
      item.responsibleFunction,
      item.responsibleEmail,
      item.responsibleTelephone
    ]);
    setCSVDigitalData([...csvTableData]);
  }

  function setScholarCsvValues(data) {
    const csvTableData = data.map((item) => [
      item._id,
      item.name,
      item.address,
      item.postalCode,
      item.city,
      item.typeEstablishment,
      item.region,
      item.academy,
      item.responsibleName,
      item.responsibleFirstName,
      item.responsibleFunction,
      item.responsibleEmail,
      item.responsibleTelephone,
      item.responsibleMissionFuture,
      item.classLevel,
      item.classNumbers,
      item.classVisitor,
      item.classVisitDay,
      item.classLunchSite,
      item.classEstimateTime,
      item.selectedSlot_Date,
      item.selectedSlot_Day,
      item.selectedSlot_Time,
      item.selectedSlot_label
    ]);
    setCSVScholarData([...csvTableData]);
  }

  function handleDownloadExcel(type) {
    type === 'scholar'
      ? downloadExcel({
          fileName: 'scholar-acadmey-compiled-data',
          tablePayload: {
            header: displayScholarCols,
            body: csvScholarData
          }
        })
      : downloadExcel({
          fileName: 'digital-acadmey-compiled-data',
          tablePayload: {
            header: displayDigitalCols,
            body: csvDigitalData
          }
        });
  }

  return (
    <div className={`pt-20 px-4 md:pt-[200px] bg-white min-h-screen`}>
      <div>
        <div className="flex justify-center items-center text-2xl text-[#3130ED] underline mb-3">
          GROUPES SCOLAIRES
        </div>
        {scholarData.length ? (
          <div className="px-4 sm:px-6 lg:px-8 mb-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {!loading && (
                    <div className="mb-5">
                      <CSVLink
                        data={[displayScholarCols, ...csvScholarData]}
                        filename="scholar-acadmey-compiled-data.csv"
                        className="bg-lyon-purple px-3 py-2 rounded-md mr-3">
                        Download CSV
                      </CSVLink>
                      <button
                        onClick={() => handleDownloadExcel('scholar')}
                        className="bg-lyon-purple px-3 py-1.5 rounded-md">
                        Download Excel
                      </button>
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        {displayScholarCols.map((column, key) => (
                          <th
                            key={key}
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center">
                            {column}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {scholarData.map((item, itemKey) => (
                        <tr key={itemKey}>
                          {scholarCols.map((cols, key) => (
                            <td
                              key={key}
                              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              {item[cols]}
                            </td>
                          ))}
                        </tr>
                      ))}
                      {scholarData.length < 10 &&
                        [...new Array(10 - scholarData.length)].map(() => (
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center pb-12 border-b-[1px] border-[#10111114]">
            <div className="text-[#101111ad]">Pas disponible</div>
          </div>
        )}
      </div>
      <div className="mt-12">
        <div className="flex justify-center items-center text-2xl text-[#3130ED] underline mb-3">
          MISSION FUTURE -L’EXPéRIENCE NUMéRIQUE
        </div>
        {digitalData.length ? (
          <div className="px-4 sm:px-6 lg:px-8 mb-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {!loading && (
                    <div className="mb-5">
                      <CSVLink
                        data={[displayDigitalCols, ...csvDigitalData]}
                        filename="digital-acadmey-compiled-data.csv"
                        className="bg-lyon-purple px-3 py-2 rounded-md mr-3">
                        Download CSV
                      </CSVLink>
                      <button
                        onClick={() => handleDownloadExcel('digital')}
                        className="bg-lyon-purple px-3 py-1.5 rounded-md">
                        Download Excel
                      </button>
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        {displayDigitalCols.map((column, key) => (
                          <th
                            key={key}
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center">
                            {column}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {digitalData.map((item, itemKey) => (
                        <tr key={itemKey}>
                          {digitalCols.map((cols, key) => (
                            <td
                              key={key}
                              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              {item[cols]}
                            </td>
                          ))}
                        </tr>
                      ))}
                      {digitalData.length < 10 &&
                        [...new Array(10 - digitalData.length)].map(() => (
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center pb-12 ">
            <div className="text-[#101111ad]">Pas disponible</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcademyData;
