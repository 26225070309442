import React from 'react';

import TransparentBox from '../../../components/transparentBox/TransparentBox';
import blob from '../../../assets/images/blobs/blob_2.png';

const Comment = () => {
  return (
    <section id="comment" className="px-1 md:px-3">
      <img
        src={blob}
        alt="Blob"
        className="blob top-[-10%] left-[-20%] md:top-[-60%] md:left-[-30%]"
      />
      <TransparentBox extraClass="mt-14">
        <p className="text-base md:text-2xl uppercase">
          À <span className="font-wskf tracking-[2px] text-3xl md:text-4xl pl-1 pr-1">qui</span>{' '}
          s’adresse le programme de visite scolaire Worldskills ?
        </p>
        <p className="text-md md:text-base">
          La visite gratuite de la Finale Nationale WorldSkills 2023 (voir Option 1 ci-dessous) est
          ouverte à tous les établissements scolaires. Différents outils de préparation et
          d’accompagnement seront mis à disposition de ces établissements au mois de mai.
        </p>
        <p className="text-md md:text-base">
          Pour les classes qui ne peuvent se rendre à Lyon, WorldSkills a élaboré un outil numérique
          (voir Option 2 ci-dessous) qui vise surtout les collégiens. En lien avec la Finale
          Nationale, WorldSkills a décidé de mettre l’outil à disposition de tous les élèves à
          partir de 12 ans.
        </p>
      </TransparentBox>
      <TransparentBox extraClass="mt-14 mb-0">
        <p className="text-base md:text-2xl uppercase">
          <span className="font-wskf tracking-[2px] text-3xl md:text-4xl pr-1">Comment</span> ma
          classe peut-elle profiter de la 47e compétition WorldSkills ?
        </p>
        <p className="text-base md:text-2xl uppercase">
          Ouvrir le champ des possibles et promouvoir l’excellence professionnelle auprès des élèves
        </p>
        <p className="text-md md:text-base">
          Sur le site Eurexpo à Lyon ou par le biais d’outils numériques, la 47e Finale Nationale
          WorldSkills donne à voir des jeunes passionnés par leur métier et qui se sont engagés pour
          devenir des champions de leur discipline. C’est une opportunité unique de découvrir de
          très nombreux métiers en situation réelle – de la taille de pierre à la cyber sécurité en
          passant par la robotique. Pendant 3 jours, les élèves peuvent s’identifier à ces jeunes
          champions qui étaient hier à leur place en collège ou en lycée professionnel et s’inspirer
          de leur réussite pour construire leur parcours.
        </p>
        <p className="text-md md:text-base">
          Pour mieux connaitre les champions de chaque région, WorldSkills France en coopération
          avec l’Onisep, a développé l’outil mission future. Il permet aux élèves en amont d’une
          visite à Lyon ou d’une participation numérique à l’évènement de découvrir leurs forces et
          leur talent. La plateforme mission future les met ensuite en relation avec des jeunes
          CHAMPIONS qui ont déjà réussi dans un métier avec des dispositions personnelles (forces)
          similaires. Ceci permet aux élèves de se projeter dans de nouveaux métiers qu’ils
          n’auraient peut-être pas envisagés et surtout de créer un lien avec des Champions qu’ils
          pourraient décider de soutenir ensuite.
        </p>
      </TransparentBox>
    </section>
  );
};

export default Comment;
