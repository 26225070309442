import React, { useEffect, useState } from 'react';
import { getSlotsWithBookingsAPI } from '../../services';

const CardComponent = ({}) => {
  const [slotsData, setSlotsData] = useState([]);

  const getSlotsWihtBooking = async () => {
    try {
      const { data } = await getSlotsWithBookingsAPI();
      console.log(data);
      setSlotsData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSlotsWihtBooking();
  }, []);

  const getSlotSelector = (slot, selectedSlots = []) => {
    const selectedSlotsOnDay = selectedSlots.filter((item) => item.selectedSlot.slot === slot.type);

    return selectedSlotsOnDay;
  };

  const slotDisplay = (slotsGroup) => {
    return slotsGroup.slot.map((slot) => {
      const selectedSLotsByUser = getSlotSelector(slot, slotsGroup.selectedSlots);

      return (
        <>
          <p className="flex pb-0">
            <strong className="mr-1">Slot Number:</strong> {slot.type}{' '}
            <p className="font-semibold ml-2 text-lg pb-0">
              {selectedSLotsByUser.length > 1 ? '🚨' : slot.isBooked ? '✅ ' : '🏷️'}
            </p>
          </p>
          {selectedSLotsByUser.length === 0 && (
            <div className={`shadow-lg border h-10 bg-green-300 mb-3`}></div>
          )}
          {selectedSLotsByUser.map((item) => (
            <div
              className={`shadow-lg border grid grid-cols-2 gap-x-3 mb-3 ${
                selectedSLotsByUser.length > 1 && 'bg-red-300'
              }`}>
              <p className="pb-0">Reference ID: {item.parent_data._id}</p>
              <p className="pb-0">email: {item.parent_data.email}</p>
              <p className="pb-0">responsibleName: {item.parent_data.responsibleName}</p>
              <p className="pb-0">slot: {item.parent_data.slot}</p>
              <p className="pb-0">
                CreatedAt: {new Date(item.parent_data.createdAt).toLocaleDateString()}{' '}
                {new Date(item.parent_data.createdAt).toLocaleTimeString()}
              </p>
            </div>
          ))}
        </>
      );
    });
  };
  return (
    <div className="p-4">
      {slotsData.map((workshop = []) => (
        <div key={workshop._id} className="bg-white shadow-2xl p-4 rounded-lg mb-4">
          <h2 className="text-xl font-bold mb-4">
            {workshop.date} ({workshop.type})
          </h2>
          {workshop.professions.map((profession = []) => (
            <>
              <h2 className="text-base mb-4">{profession.title}</h2>
              {console.log('profession', profession)}
              <div key={profession._id} className="mb-3">
                {profession.slots.map((slotsGroup) => (
                  <div className="p-2 border rounded mt-2">
                    <h3 className="text-md font-semibold">{slotsGroup?.time}</h3>
                    {slotDisplay(slotsGroup)}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
