import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import TransparentBox from '../../../components/transparentBox/TransparentBox';
import ResponsiveVimeoVideo from '../../../components/responsiveVimeoVideo/ResponsiveVimeoVideo';

import blob from '../../../assets/images/blobs/blob_3.png';
import blob_2 from '../../../assets/images/blobs/blob_4.png';
import { down_triangle_white } from '../../../assets/icons';

const Options = () => {
  const [showOptionOneBox, setShowOptionOneBox] = useState(false);

  return (
    <section id="options">
      {/* Option 1 */}
      <div className="border-2 border-white rounded-xl p-4 md:p-10 mb-20">
        <img src={blob} alt="Blob" className="blob top-[-22%] left-[-30%]" />
        <img src={blob_2} alt="Blob" className="blob top-[42%] right-[-30%]" />
        <p className="flex flex-wrap">
          <span className="text-3xl md:text-4xl font-wskf tracking-[2px] uppercase w-full md:w-[16%]">
            Option 1 :
          </span>
          <span className="text-xl md:text-2xl uppercase w-full md:w-[80%]">
            Vivre l’expérience WorldSkills sur site à Lyon
            <br />
            14 - 16 septembre
          </span>
        </p>
        <div
          className="relative w-full overflow-hidden border-none mx-auto mb-4"
          style={{
            maxWidth: '100%',
            width: '100%'
          }}>
          <ResponsiveVimeoVideo
            url={
              'https://player.vimeo.com/video/813654389?h=6f8eccf40a&color=ff9933&title=0&byline=0&portrait=0'
            }
          />
        </div>
        <p className="text-md md:text-base">
          Comme dans le sport, une compétition des métiers crée une atmosphère très spéciale et
          unique qui enthousiasme. Sur place, la passion des jeunes compétiteurs est communicative,
          et permet de vivre le suspens, la dextérité, la précision et la créativité dans des
          métiers très différents.
        </p>
        <p className="text-md md:text-base">
          Assurez une place à votre classe en vous inscrivant dès maintenant ! Mi-mai nous vous
          enverrons des modalités de visite, vous permettant de préparer votre visite en autonomie
          avec des outils préparatoires ou en réservant des parcours accompagnés. Nous vous incitons
          à réserver vos moyens de transports dès votre inscription.
        </p>
        <p
          className="text-md md:text-base cursor-pointer"
          onClick={() => setShowOptionOneBox(!showOptionOneBox)}>
          Cliquez{' '}
          <span className="text-3xl md:text-4xl font-wskf tracking-[2px] underline decoration-white decoration-2 underline-offset-2">
            IcI
          </span>{' '}
          <span>
            pour plus d’informations pour la visite sur site à Lyon.{' '}
            <span className="inline">{down_triangle_white}</span>
          </span>
        </p>

        {showOptionOneBox && (
          <TransparentBox extraClass="mb-2">
            <ul className="list-disc">
              <li className="text-md md:text-base">
                Lieu : EUREXPO LYON, Boulevard de l'Europe – 69680 Chassieu
              </li>
              <li className="text-md md:text-base">
                Dates : Jeudi, 14 septembre au samedi, 16 septembre 2023
              </li>
              <li className="text-md md:text-base">
                Horaires d'ouverture : Jeudi et vendredi 09h00 – 17h00 ; samedi 09h00 - 16h30 (les
                horaires de compétition ne sont pas encore connus à ce jour)
              </li>
              <li className="text-md md:text-base">
                Billeterie : Les billets vous seront envoyés par mail dès ouverture de la
                billeterie.
              </li>
              <li className="text-md md:text-base">
                Accès (transports en commun) : Tramway T5 (Arrêt terminus Eurexpo Lyon) – Bus N100
              </li>
              <li className="text-md md:text-base">
                Restauration : vente sur place + espaces pique-nique
              </li>
              <li className="text-md md:text-base">
                80 000 m² / 5 halls / plus de 800 compétiteurs sur 69 métiers
              </li>
              <li className="text-md md:text-base">Temps de visite minimum à prévoir ≥ 3h</li>
            </ul>
          </TransparentBox>
        )}
        <p className="text-md md:text-base">
          Cliquez{' '}
          <Link to={'/form_1'}>
            <span className="text-3xl md:text-4xl font-wskf tracking-[2px] underline decoration-white decoration-2 underline-offset-2">
              IcI
            </span>
          </Link>{' '}
          <span>pour renseigner le formulaire d’inscription de la visite sur site à Lyon.</span>
        </p>
        {/* Option 2 */}
        <p className="flex flex-wrap  mt-14">
          <span className="text-3xl md:text-4xl font-wskf tracking-[2px] uppercase w-full md:w-[16%]">
            Option 2 :
          </span>
          <span className="text-xl md:text-2xl uppercase w-full md:w-[80%]">
            Inscrivez votre classe pour l’expérience numérique
            <br />
            mission future et remportez une interview
            <br />
            exclusive avec un Champion WorldSkills
          </span>
        </p>
        <div
          className="relative w-full overflow-hidden border-none mx-auto mb-4"
          style={{
            maxWidth: '100%',
            width: '100%'
          }}>
          <ResponsiveVimeoVideo
            url={
              'https://player.vimeo.com/video/813661574?h=6f8eccf40a&color=ff9933&title=0&byline=0&portrait=0'
            }
          />
        </div>
        <p className="text-md md:text-base">
          Malheureusement, toutes les classes n’auront pas la possibilité de se rendre à Lyon. Nous
          mobilisons donc notre outil mission future de découverte par les élèves de leurs qualités,
          qui a été élargi pour inclure une association des élèves avec les compétiteurs
          représentant chaque région. Ils pourront ainsi eux aussi s’embarquer pour une première
          découverte de la diversité et de la richesse des métiers et s’identifier à un champion.
          Une interview en ligne d’un Champion ou d’une Championne dans la semaine suivant la
          compétition pourrait être le point culminant de ce voyage de découverte (18-22 septembre).
        </p>
        <p className="text-md md:text-base">
          Assurez une interview à votre classe en vous inscrivant dès maintenant !
        </p>
        <p className="text-md md:text-base pb-0">
          Cliquez{' '}
          <Link to={'/form_2'}>
            <span className="text-3xl md:text-4xl font-wskf tracking-[2px] underline decoration-white decoration-2 underline-offset-2">
              IcI
            </span>
          </Link>{' '}
          <span>
            pour renseigner le formulaire d’inscription pour une participation numérique. Lyon.
          </span>
        </p>
        {/* Contact */}
        <p className="flex flex-wrap w-full md:max-w-[90%] mx-auto mt-20 pb-0">
          <span className="text-2xl md:text-4xl font-wskf tracking-[2px] uppercase w-full md:w-[16%]">
            contact :
          </span>
          <span className="text-sm md:text-2xl uppercase w-full md:w-[80%]">
            En cas de question ou de problème, écrivez-nous à<br />
            <a href="mailto:visite-47worldskills@cofom.org" className="underline">
              visite-47worldskills@cofom.org
            </a>
          </span>
        </p>
      </div>
    </section>
  );
};

export default Options;
