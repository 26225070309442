import React, { useEffect, useState } from 'react';

const Loader = () => {
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div>
        <img src="/loading.svg" />
      </div>
    </div>
  );
};

export default Loader;
