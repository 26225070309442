import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { downloadExcel } from 'react-export-table-to-excel';

import { getDigitalData } from '../../services';
const DigitalData = ({}) => {
  const [digitalData, setDigital] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns] = useState([
    '_id',
    'name',
    'address',
    'postalCode',
    'city',
    'type',
    'region',
    'academy',
    'responsibleLastName',
    'responsibleFirstName',
    'responsibleFunction',
    'responsibleEmail',
    'responsibleTelephone'
  ]);

  const [displayCols] = useState([
    'ID',
    'Nom établissement',
    'Adresse',
    'Code postal',
    'Ville',
    'Type établissement',
    'Région',
    'Académie',
    'Nom responsable',
    'Prénom responsable',
    'Fonction',
    'E-Mail',
    'Téléphone'
  ]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { data } = await getDigitalData();
      setDigital(data);
      setCsvValues(data);
      setLoading(false);
      toast.success('Enregistrement réussi ! Merci !');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting the form');
    } finally {
    }
  };

  function setCsvValues(data) {
    const csvTableData = data.map((item) => [
      item._id,
      item.name,
      item.address,
      item.postalCode,
      item.city,
      item.type,
      item.region,
      item.academy,
      item.responsibleLastName,
      item.responsibleFirstName,
      item.responsibleFunction,
      item.responsibleEmail,
      item.responsibleTelephone
    ]);
    setCSVData([...csvTableData]);
  }

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'digital-compiled-data',
      tablePayload: {
        header: displayCols,
        body: csvData
      }
    });
  }

  return (
    <div className={`pt-20 md:pt-[200px] bg-white min-h-screen`}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {!loading && (
                <div className="mb-5">
                  <CSVLink
                    data={[displayCols, ...csvData]}
                    filename="digital-compiled-data.csv"
                    className="bg-lyon-purple px-3 py-2 rounded-md mr-3">
                    Download CSV
                  </CSVLink>
                  <button
                    onClick={handleDownloadExcel}
                    className="bg-lyon-purple px-3 py-1.5 rounded-md">
                    Download Excel
                  </button>
                </div>
              )}
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {displayCols.map((column, key) => (
                      <th
                        key={key}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center">
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {digitalData.map((item, itemKey) => (
                    <tr key={itemKey}>
                      {columns.map((cols, key) => (
                        <td
                          key={key}
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                          {item[cols]}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {digitalData.length < 10 &&
                    [...new Array(10 - digitalData.length)].map(() => (
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalData;
