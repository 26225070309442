import { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';

export const stringArrayToOptionsList = (inputArray) => {
  return inputArray.map((value) => ({ value: value, label: value }));
};

export const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Outlet />;
};
