import React, { useState, useEffect, useContext } from 'react';

import Input from './Input';
import { stringArrayToOptionsList } from '../../util/helpers';
import typeList from '../../data/type.list';
import Radio from './Radio';
import Accordian from '../common/Accordian';
import arrivalTimeList from '../../data/arrivalTime.list';

import { getRegions, getAcademies } from '../../services';

const SchoolForm = ({
  t1,
  t2,
  setFieldValue,
  schoolFields,
  ResponsibleFields,
  errors,
  touched,
  handleChange,
  handleBlur,
  selectSlot,
  removeClass,
  removeResponsible,
  activeResponsible,
  setActiveResponsible
}) => {
  const [showTypeEstOtherInput, setShowTypeEstOtherInput] = useState(false);
  const [regions, setRegions] = useState([]);
  const [academie, setAcademies] = useState([]);

  useEffect(() => {
    getRegionsData();
    getAcademiesData();
  }, []);

  const getRegionsData = async () => {
    try {
      const { data } = await getRegions();
      const filteredData = data.map(({ _id, name_fr }) => ({
        value: _id,
        label: name_fr
      }));
      setRegions(filteredData);
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  const getAcademiesData = async () => {
    try {
      const { data } = await getAcademies();
      const filteredData = data.map(({ _id, name }) => ({
        value: _id,
        label: name
      }));
      setAcademies(filteredData);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const onOpenResponsibile = (id) => {
    setActiveResponsible(id);
  };

  const onClickSelectSlotHandler = (key, index) => {
    const selectFor = {
      responsible: key,
      classes: index
    };
    selectSlot(selectFor);
  };

  const removeClassData = (resId, classId) => {
    removeClass(resId, classId);
  };

  const removeResponsibleData = (ind) => {
    removeResponsible(ind);
  };

  const onClickClass = () => {};
  return (
    <>
      <div>
        <p>*Les champs marqués par un astérisque sont obligatoires.</p>
      </div>
      <div>
        <div className="bg-[#F9F9F9] p-2 rounded-lg">
          <div className="grid grid-rows-1 md:grid-cols-2 gap-y-3 gap-x-12">
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', 'Nom de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'schoolData.name',
                    value: schoolFields.name,
                    touched: touched.name,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.schoolData?.name && !!errors?.schoolData?.name ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.schoolData.name}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', 'Adresse de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'schoolData.address',
                    value: schoolFields.address,
                    name: 'schoolData.address',
                    touched: touched.address,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.schoolData?.address && !!errors?.schoolData?.address ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.schoolData.address}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', 'Code postal de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'schoolData.postalCode',
                    value: schoolFields.postalCode,
                    touched: touched.postalCode,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.schoolData?.postalCode && !!errors?.schoolData?.postalCode ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.schoolData.postalCode}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', 'Ville de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'schoolData.city',
                    value: schoolFields.city,
                    touched: touched.city,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.schoolData?.city && !!errors?.schoolData?.city ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.schoolData.city}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Région de l’établissement*'],
                  input: {
                    options: regions,
                    placeholder: 'Sélectionner',
                    name: 'schoolData.region',
                    value: schoolFields.region,
                    touched: touched.region,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('schoolData.region', val);
                    }
                  }
                }}
              />
              {!!touched?.schoolData?.region && !!errors?.schoolData?.region ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.schoolData.region}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Académie de  l’établissement*'],
                  input: {
                    options: academie,
                    placeholder: 'Sélectionner',
                    name: 'schoolData.academy',
                    value: schoolFields.academy,
                    touched: touched.academy,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('schoolData.academy', val);
                    }
                  }
                }}
              />
              {!!touched?.schoolData?.academy && !!errors?.schoolData?.academy ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.schoolData.academy}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Type d’établissement*'],
                  input: {
                    options: stringArrayToOptionsList(typeList),
                    placeholder: 'Sélectionner',
                    name: 'schoolData.type',
                    value: schoolFields.type,
                    touched: touched.type,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('schoolData.type', val);
                      if (val.value && val.value === 'Autres') {
                        setShowTypeEstOtherInput(true);
                      } else {
                        setShowTypeEstOtherInput(false);
                      }
                    }
                  }
                }}
              />
              {!!touched?.schoolData?.type && !!errors?.schoolData?.type ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.schoolData.type}</p>
              ) : null}
            </span>
          </div>
        </div>
        {ResponsibleFields.map((field, keys) => {
          // if (item !== "data") {
          let id = keys;
          return (
            <div className="bg-[#F9F9F9]" key={keys}>
              <Accordian
                info={true}
                title={t1}
                keyData={id}
                toolTip={`Un ou une responsable peut renseigner plusieurs classes à son nom. Cependant, vu que les billets d’entrée et les Parcours Accompagnés sont attribués à la classe et non au responsable, chaque classe doit être enregistrée séparément. Nous conseillons d’enregistrer chaque classe avec une personne responsable.`}
                setOpen={id === activeResponsible}
                clickHandler={() => onOpenResponsibile(id)}
                removeResponsibleData={removeResponsibleData}
                isClose={ResponsibleFields.length !== 1}>
                <div className="bg-[#F9F9F9] p-2 rounded-lg">
                  <div className="grid grid-rows-1 md:grid-cols-2 gap-y-3 gap-x-12">
                    <span className="block">
                      <Input
                        theme={'white'}
                        data={{
                          label: ['', '', `Nom*`],
                          input: {
                            placeholder: 'écrire ici',
                            name: `responsible[${keys}].responsibleName`,
                            value: field.responsibleName,
                            touched: touched.responsibleName,
                            onBlur: handleBlur,
                            onChange: handleChange
                          }
                        }}
                      />
                      {!!touched?.responsible?.[keys]?.responsibleName &&
                      !!errors?.responsible?.[keys]?.responsibleName ? (
                        <p className="text-red-400 pb-0 text-xs uppercase">
                          {errors.responsible[keys].responsibleName}
                        </p>
                      ) : null}
                    </span>
                    <span className="block">
                      <Input
                        theme={'white'}
                        data={{
                          label: ['', '', `Prénom*`],
                          input: {
                            placeholder: 'écrire ici',
                            name: `responsible[${keys}].responsibleFirstName`,
                            value: field.responsibleFirstName,
                            touched: touched.responsibleFirstName,
                            onBlur: handleBlur,
                            onChange: handleChange
                          }
                        }}
                      />
                      {!!touched?.responsible?.[keys]?.responsibleFirstName &&
                      !!errors?.responsible?.[keys]?.responsibleFirstName ? (
                        <p className="text-red-400 pb-0 text-xs uppercase">
                          {errors.responsible[keys].responsibleFirstName}
                        </p>
                      ) : null}
                    </span>
                    <span className="block">
                      <Input
                        theme={'white'}
                        data={{
                          label: ['', '', 'Fonction*'],
                          input: {
                            placeholder: 'écrire ici',
                            name: `responsible[${keys}].responsibleFunction`,
                            value: field.responsibleFunction,
                            touched: touched.responsibleFunction,
                            onBlur: handleBlur,
                            onChange: handleChange
                          }
                        }}
                      />
                      {!!touched?.responsible?.[keys]?.responsibleFunction &&
                      !!errors?.responsible?.[keys]?.responsibleFunction ? (
                        <p className="text-red-400 pb-0 text-xs uppercase">
                          {errors.responsible[keys].responsibleFunction}
                        </p>
                      ) : null}
                    </span>
                    <span className="block">
                      <Input
                        theme={'white'}
                        data={{
                          label: ['', '', 'Email*'],
                          input: {
                            placeholder: 'écrire ici',
                            name: `responsible[${keys}].responsibleEmail`,
                            value: field.responsibleEmail,
                            touched: touched.responsibleEmail,
                            onBlur: handleBlur,
                            onChange: handleChange
                          }
                        }}
                      />
                      {!!touched?.responsible?.[keys]?.responsibleEmail &&
                      !!errors?.responsible?.[keys]?.responsibleEmail ? (
                        <p className="text-red-400 pb-0 text-xs uppercase">
                          {errors.responsible[keys].responsibleEmail}
                        </p>
                      ) : null}
                    </span>
                    <span className="block">
                      <Input
                        theme={'white'}
                        data={{
                          label: ['', '', 'Télèphone*'],
                          input: {
                            placeholder: 'écrire ici',
                            name: `responsible[${keys}].responsibleTelephone`,
                            value: field.responsibleTelephone,
                            touched: touched.responsibleTelephone,
                            onBlur: handleBlur,
                            onChange: handleChange
                          }
                        }}
                      />
                      {!!touched?.responsible?.[keys]?.responsibleTelephone &&
                      !!errors?.responsible?.[keys]?.responsibleTelephone ? (
                        <p className="text-red-400 pb-0 text-xs uppercase">
                          {errors.responsible[keys].responsibleTelephone}
                        </p>
                      ) : null}
                    </span>
                    <span className="block mt-4">
                      <p className="">
                        mission future - Je souhaite avoir accès au Portail Enseignants mission
                        future* (Cliquez sur non, si vous avez déjà un accès à ce portail)
                      </p>
                      <span className="block">
                        <div className="flex">
                          <Radio
                            data={{
                              label: ['', '', 'Oui'],
                              name: `responsible[${keys}].responsibleMissionFuture`,
                              value: 'Oui',
                              checked: field.responsibleMissionFuture === 'Oui',
                              onChange: (val) =>
                                setFieldValue(
                                  `responsible[${keys}].responsibleMissionFuture`,
                                  val.target.defaultValue
                                )
                            }}
                            theme={'white'}
                          />
                          <Radio
                            data={{
                              label: ['', '', 'Non'],
                              name: `responsible[${keys}].responsibleMissionFuture`,
                              value: 'Non',
                              checked: field.responsibleMissionFuture === 'Non',
                              onChange: (val) =>
                                setFieldValue(
                                  `responsible[${keys}].responsibleMissionFuture`,
                                  val.target.defaultValue
                                )
                            }}
                            theme={'white'}
                          />
                        </div>
                        {!!touched?.responsible?.[keys]?.responsibleMissionFuture &&
                        !!errors?.responsible?.[keys]?.responsibleMissionFuture ? (
                          <p className="text-red-400 pb-0 text-xs uppercase">
                            {errors.responsible[keys].responsibleMissionFuture}
                          </p>
                        ) : null}
                      </span>
                    </span>
                  </div>
                </div>
                {field.classes.map((classData, index) => {
                  // if (item !== "data") {
                  return (
                    <Accordian
                      key={index}
                      info={false}
                      title={t2}
                      keyData={index}
                      setOpen={activeResponsible === id}
                      clickHandler={onClickClass}
                      removeClassData={() => {
                        removeClassData(id, index);
                      }}
                      isClose={field.classes.length !== 1}>
                      <div>
                        <div className="bg-[#F9F9F9] p-2 rounded-lg">
                          <div className="grid grid-rows-1 md:grid-cols-2 gap-y-3 gap-x-12">
                            <span className="block">
                              <Input
                                theme={'white'}
                                data={{
                                  label: [
                                    '',
                                    '',
                                    `Niveau de la CLasse Concernée*
                                  (niveau que la classe aura en septembre)`
                                  ],
                                  input: {
                                    placeholder: 'exemple : 5ème ',
                                    name: `responsible[${keys}]classes[${index}].classLevel`,
                                    value: classData.classLevel,
                                    touched: touched.classLevel,
                                    onBlur: handleBlur,
                                    onChange: handleChange
                                  }
                                }}
                              />
                              {!!touched?.responsible?.[keys]?.classes?.[index]?.classLevel &&
                              !!errors?.responsible?.[keys]?.classes?.[index]?.classLevel ? (
                                <p className="text-red-400 pb-0 text-xs uppercase">
                                  {errors.responsible[keys].classes[index].classLevel}
                                </p>
                              ) : null}
                            </span>
                            <span className="block">
                              <Input
                                theme={'white'}
                                data={{
                                  label: [
                                    '',
                                    '',
                                    `Numérotez votre classe* (01 pour la première classe; 02 pour la deuxième, etc.; si vous une classe = 01 )`
                                  ],
                                  input: {
                                    placeholder: 'exemple : 02',
                                    name: `responsible[${keys}]classes[${index}].classNumbers`,
                                    value: classData.classNumbers,
                                    touched: touched.classNumbers,
                                    onBlur: handleBlur,
                                    onChange: handleChange
                                  }
                                }}
                              />
                              {!!touched?.responsible?.[keys]?.classes?.[index]?.classNumbers &&
                              !!errors?.responsible?.[keys]?.classes?.[index]?.classNumbers ? (
                                <p className="text-red-400 pb-0 text-xs uppercase">
                                  {errors.responsible[keys].classes[index].classNumbers}
                                </p>
                              ) : null}
                            </span>
                            <span className="block">
                              <Input
                                theme={'white'}
                                data={{
                                  label: [
                                    '',
                                    '',
                                    `Nombre total de visiteurs prévu pour cette 
                                  classe (élèves + encadrement)*`
                                  ],
                                  input: {
                                    placeholder: 'écrire ici',
                                    name: `responsible[${keys}]classes[${index}].classVisitor`,
                                    value: classData.classVisitor,
                                    touched: touched.classVisitor,
                                    onBlur: handleBlur,
                                    onChange: handleChange
                                  }
                                }}
                              />
                              {!!touched?.responsible?.[keys]?.classes?.[index]?.classVisitor &&
                              !!errors?.responsible?.[keys]?.classes?.[index]?.classVisitor ? (
                                <p className="text-red-400 pb-0 text-xs uppercase">
                                  {errors.responsible[keys].classes[index].classVisitor}
                                </p>
                              ) : null}
                            </span>
                            <span className="block">
                              <p className="">
                                Jour de la visite* <br /> (Jeudi 14 au samedi 16 septembre)
                              </p>
                              <span className="block">
                                <div className="flex">
                                  <Radio
                                    data={{
                                      label: ['', '', 'Jeudi'],
                                      name: `responsible[${keys}]classes[${index}].classVisitDay`,
                                      value: 'Jeudi',
                                      checked: classData.classVisitDay === 'Jeudi',
                                      onChange: (val) =>
                                        setFieldValue(
                                          `responsible[${keys}]classes[${index}].classVisitDay`,
                                          val.target.defaultValue
                                        )
                                    }}
                                    theme={'white'}
                                  />
                                  <Radio
                                    data={{
                                      label: ['', '', 'Vendredi'],
                                      name: `responsible[${keys}]classes[${index}].classVisitDay`,
                                      value: 'Vendredi',
                                      checked: classData.classVisitDay === 'Vendredi',
                                      onChange: (val) =>
                                        setFieldValue(
                                          `responsible[${keys}]classes[${index}].classVisitDay`,
                                          val.target.defaultValue
                                        )
                                    }}
                                    theme={'white'}
                                  />
                                  <Radio
                                    data={{
                                      label: ['', '', 'Samedi'],
                                      name: `responsible[${keys}]classes[${index}].classVisitDay`,
                                      value: 'Samedi',
                                      checked: classData.classVisitDay === 'Samedi',
                                      onChange: (val) =>
                                        setFieldValue(
                                          `responsible[${keys}]classes[${index}].classVisitDay`,
                                          val.target.defaultValue
                                        )
                                    }}
                                    theme={'white'}
                                  />
                                </div>
                                {!!touched?.responsible?.[keys]?.classes?.[index]?.classVisitDay &&
                                !!errors?.responsible?.[keys]?.classes?.[index]?.classVisitDay ? (
                                  <p className="text-red-400 pb-0 text-xs uppercase">
                                    {errors.responsible[keys].classes[index].classVisitDay}
                                  </p>
                                ) : null}
                              </span>
                            </span>
                            <span className="block">
                              <p className="">
                                Déjeuner sur Place ? (Jeudi 14 au samedi 16 Septembre)
                              </p>
                              <span className="block">
                                <div className="flex">
                                  <Radio
                                    data={{
                                      label: ['', '', 'Oui'],
                                      name: `responsible[${keys}]classes[${index}]classLunchSite`,
                                      value: 'Oui',
                                      checked: classData.classLunchSite === 'Oui',
                                      onChange: (val) =>
                                        setFieldValue(
                                          `responsible[${keys}]classes[${index}]classLunchSite`,
                                          val.target.defaultValue
                                        )
                                    }}
                                    theme={'white'}
                                  />
                                  <Radio
                                    data={{
                                      label: ['', '', 'Non'],
                                      name: `responsible[${keys}]classes[${index}]classLunchSite`,
                                      value: 'Non',
                                      checked: classData.classLunchSite === 'Non',
                                      onChange: (val) =>
                                        setFieldValue(
                                          `responsible[${keys}]classes[${index}]classLunchSite`,
                                          val.target.defaultValue
                                        )
                                    }}
                                    theme={'white'}
                                  />
                                </div>
                                {!!touched?.responsible?.[keys]?.classes?.[index]?.classLunchSite &&
                                !!errors?.responsible?.[keys]?.classes?.[index]?.classLunchSite ? (
                                  <p className="text-red-400 pb-0 text-xs uppercase">
                                    {errors.responsible[keys].classes[index].classLunchSite}
                                  </p>
                                ) : null}
                              </span>
                            </span>
                            <span className="block">
                              <Input
                                theme={'white'}
                                type={'select'}
                                data={{
                                  label: ['', '', 'Heure d’arrivée prévue à Eurexpo ?'],
                                  input: {
                                    options: stringArrayToOptionsList(arrivalTimeList),
                                    placeholder: 'Sélectionner',
                                    name: `responsible[${keys}]classes[${index}].classEstimateTime`,
                                    value: classData.classEstimateTime,
                                    touched: touched.classEstimateTime,
                                    onBlur: handleBlur,
                                    onChange: (val) => {
                                      setFieldValue(
                                        `responsible[${keys}]classes[${index}].classEstimateTime`,
                                        val
                                      );
                                    }
                                  }
                                }}
                              />
                              {!!touched?.responsible?.[keys]?.classes?.[index]
                                ?.classEstimateTime &&
                              !!errors?.responsible?.[keys]?.classes?.[index]?.classEstimateTime ? (
                                <p className="text-red-400 pb-0 text-xs uppercase">
                                  {errors.responsible[keys].classes[index].classEstimateTime}
                                </p>
                              ) : null}
                            </span>
                          </div>
                          <div className="sm:flex gap-4 mt-8">
                            <div className="sm:w-[60%] md:w-[70%] lg:w-[50%] mb-2 lg:mb-0">
                              PARCOURS ACCOMPAGNÉ - Je souhaite réserver un Parcours Accompagné pour
                              ma classe
                            </div>
                            <div>
                              <button
                                onClick={() => {
                                  onClickSelectSlotHandler(keys, index);
                                }}
                                // disabled = {(classData.selectedSlot && classData.selectedSlot.date)}
                                className="border-[#3130ED] rounded-full text-base border-2 border-solid p-2 bg-[#3130ed29]">
                                Cliquez ICI
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordian>
                  );
                  // }
                })}
              </Accordian>
            </div>
          );
          // }
        })}
      </div>
    </>
  );
};

export default SchoolForm;
