import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { downloadExcel } from 'react-export-table-to-excel';

import { getScholarsData } from '../../services';
const Scholars = ({}) => {
  const [scholars, setScholars] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns] = useState([
    '_id',
    'name',
    'address',
    'postalCode',
    'city',
    'typeEstablishment',
    'region',
    'academy',
    'responsibleName',
    'responsibleFirstName',
    'responsibleFunction',
    'responsibleEmail',
    'responsibleTelephone',
    'responsibleMissionFuture',
    'classLevel',
    'classNumbers',
    'classVisitor',
    'classVisitDay',
    'classLunchSite',
    'classEstimateTime',
    'selectedSlot_Date',
    'selectedSlot_Day',
    'selectedSlot_Time',
    'selectedSlot_label'
  ]);

  const [displayCols] = useState([
    'ID',
    'Nom établissement',
    'Adresse',
    'Code postal',
    'Ville',
    'Type établissement',
    'Région',
    'Académie',
    'Nom responsable',
    'Prénom responsable',
    'Fonction',
    'E-Mail',
    'Téléphone',
    'mission future',
    'Niveau de la classe',
    'Numéro de la classe',
    'Nombre visiteurs',
    'Jour de visite',
    'Lunch sur site',
    "Heure d'arrivée",
    'Date parcours accompagné',
    'Jour parcours accompagné',
    'Créneau horaire',
    'Dénomination parcours'
  ]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { data } = await getScholarsData();
      const filteredData = await filterData(data);
      setScholars(filteredData);
      setCsvValues(filteredData);
      setLoading(false);
      toast.success('Enregistrement réussi ! Merci !');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting the form');
    } finally {
    }
  };

  const filterData = async (data) => {
    let newData = [];
    data.map((userItems) => {
      userItems.responsible.map((respItems) => {
        respItems.classes.map((classItems) => {
          let rowData = {
            _id: userItems._id,
            name: userItems.name,
            address: userItems.address,
            postalCode: userItems.postalCode,
            city: userItems.city,
            typeEstablishment: userItems.typeEstablishment,
            region: userItems?.region?.name,
            academy: userItems?.academy?.name,
            responsibleName: respItems.responsibleName,
            responsibleFirstName: respItems.responsibleFirstName,
            responsibleFunction: respItems.responsibleFunction,
            responsibleEmail: respItems.responsibleEmail,
            responsibleTelephone: respItems.responsibleTelephone,
            responsibleMissionFuture: respItems.responsibleMissionFuture,
            classLevel: classItems.classLevel,
            classNumbers: classItems.classNumbers,
            classVisitor: classItems.classVisitor,
            classVisitDay: classItems.classVisitDay,
            classLunchSite: classItems.classLunchSite,
            classEstimateTime: classItems.classEstimateTime.value,
            selectedSlot_Date: classItems?.selectedSlot?.date,
            selectedSlot_Day: classItems?.selectedSlot?.type,
            selectedSlot_Time: classItems?.selectedSlot?.time,
            selectedSlot_label: classItems?.selectedSlot?.label
          };
          newData.push(rowData);
        });
      });
    });
    return newData;
  };

  function setCsvValues(data) {
    const csvTableData = data.map((item) => [
      item._id,
      item.name,
      item.address,
      item.postalCode,
      item.city,
      item.typeEstablishment,
      item.region,
      item.academy,
      item.responsibleName,
      item.responsibleFirstName,
      item.responsibleFunction,
      item.responsibleEmail,
      item.responsibleTelephone,
      item.responsibleMissionFuture,
      item.classLevel,
      item.classNumbers,
      item.classVisitor,
      item.classVisitDay,
      item.classLunchSite,
      item.classEstimateTime,
      item.selectedSlot_Date,
      item.selectedSlot_Day,
      item.selectedSlot_Time,
      item.selectedSlot_label
    ]);
    setCSVData([...csvTableData]);
  }

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'scholar-compiled-data',
      tablePayload: {
        header: displayCols,
        body: csvData
      }
    });
  }

  return (
    <div className={`pt-20 md:pt-[200px] bg-white min-h-screen`}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {!loading && (
                <div className="mb-5">
                  <CSVLink
                    data={[displayCols, ...csvData]}
                    filename="scholar-compiled-data.csv"
                    className="bg-lyon-purple px-3 py-2 rounded-md mr-3">
                    Download CSV
                  </CSVLink>
                  <button
                    onClick={handleDownloadExcel}
                    className="bg-lyon-purple px-3 py-1.5 rounded-md">
                    Download Excel
                  </button>
                </div>
              )}
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {displayCols.map((column, key) => (
                      <th
                        key={key}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center">
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {scholars.map((item, itemKey) => (
                    <tr key={item.email}>
                      {columns.map((cols, key) => (
                        <td key={key} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item[cols]}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {scholars.length < 10 &&
                    [...new Array(10 - scholars.length)].map(() => (
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scholars;
