import React, { useState, useLayoutEffect } from 'react';

import blob_1 from '../../assets/images/blobs/blob_5.png';
import blob_2 from '../../assets/images/blobs/blob_6.png';
import blob_3 from '../../assets/images/blobs/blob_7.png';
import blob_4 from '../../assets/images/blobs/blob_8.png';

const FormBG = () => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setTimeout(() => {
      setHeight(document.documentElement.offsetHeight);
    }, 5);
  }, []);

  return (
    <div
      style={{
        height: `${height}px`,
        transition: 'opacity 300ms ease-in-out',
        opacity: height ? 1 : 0
      }}
      className={`w-full h-[1000px] absolute top-0 left-0 bg-transparent z-[-1] overflow-y-hidden overflow-x-clip`}>
      <div style={{ height: `${height}px` }} className="container mx-auto h-full relative p-0">
        <img
          src={blob_1}
          alt="Blob"
          className="blob_form w-[200vw] left-[-160%] top-[2%] md:top-[-10%] md:left-[-25%] md:w-[70vw]"
        />
        <img
          src={blob_2}
          alt="Blob"
          className="blob_form w-[200vw] right-[-135%] top-[30%] md:top-[25%] md:right-[-38%] md:w-[70vw]"
        />
        <img
          src={blob_3}
          alt="Blob"
          className="blob_form w-[200vw] bottom-[12%] left-[-130%] md:bottom-[-10%] md:left-[-30%] md:w-[70vw]"
        />
        <img src={blob_4} alt="Blob" className="blob_form hidden md:block top-[50%] left-[10%] " />
      </div>
    </div>
  );
};

export default FormBG;
