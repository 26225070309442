import React from 'react';

const TransparentBox = ({ extraClass = '', children }) => {
  return (
    <div
      className={`relative bg-lyon-section_bg p-4 md:p-10 mb-10 rounded-xl backdrop-blur-xl ${extraClass}`}>
      {children}
    </div>
  );
};

export default TransparentBox;
