import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { downloadExcel } from 'react-export-table-to-excel';

import { getInPersonDataAPI } from '../../services/index';

export default function Table() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [columns] = useState([
    'Académie de l’établissement',
    "Région de l'établissement",
    'Nom de l’établissement',
    'Adresse de l’établissement',
    'Code postal de l’établissement',
    'Ville de l’établissement',
    'Type de l’établissement',
    "Autres : Spécifier le type d'établissement",
    'Prénom',
    'Nom',
    'email',
    'Téléphone',
    'Fonction',
    'Niveau de la classe concernée',
    'Nombre total de personnes (y compris encadrement)',
    'Nombre de classes',
    'Nombre moyen d’élèves par classe',
    'Jour de visite',
    "Heure d'arrivée",
    'Déjeuner sur place'
  ]);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    try {
      const { data = [] } = await getInPersonDataAPI(id);
      if (data.length === 0) toast.error('Nothing is added so far');
      setData(data);
      const csvTableData = data.map((item) => [
        item.academie_establishment,
        item.region_establishment,
        item.nom_establishment,
        item.addresse_establishment,
        item.code_establishment,
        item.ville_establishment,
        item.type_establishment,
        item.type_establishment_other,
        item.lastName,
        item.firstName,
        item.email,
        item.telephone,
        item.function,
        item.niveau_classe_concernee,
        item.nombre_total_persons,
        item.nombre_total_classes,
        item.nombre_students_per_classe,
        item.quel_jour,
        item.heure_darrive,
        item.comptez_vous
      ]);
      setCSVData([...csvTableData]);
      setLoading(false);
    } catch (e) {
      const message = e.response?.data || 'Something Went Wrong';
      toast.error(message);
    }
  };

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'compiled-data',
      tablePayload: {
        header: columns,
        body: csvData
      }
    });
  }

  return (
    <div className={`pt-20 md:pt-[200px] bg-white min-h-screen`}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {!loading && (
                <div className="">
                  <CSVLink
                    data={[columns, ...csvData]}
                    filename="compiled-data.csv"
                    className="bg-lyon-purple px-3 py-2 rounded-md mr-3">
                    Download CSV
                  </CSVLink>
                  <button
                    onClick={handleDownloadExcel}
                    className="bg-lyon-purple px-3 py-1.5 rounded-md">
                    Download Excel
                  </button>
                </div>
              )}
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.academie_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.region_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.nom_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.addresse_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.code_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.ville_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.type_establishment}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.type_establishment_other}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.lastName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.firstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.telephone}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.function}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.niveau_classe_concernee}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.nombre_total_persons}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.nombre_total_classes}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.nombre_students_per_classe}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.quel_jour}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.heure_darrive}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.comptez_vous}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
