import React, { useContext, useEffect, useState } from 'react';
import { plus_icon } from '../../assets/icons';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { scolar_schema, scolar_initial_value } from '../../data/scholar_form';
import InfoPopup from '../../components/popups/infoPopup';
import MissionPopup from '../../components/popups/missionPopup';
import Input from '../../components/form/Input';
import RegisterEstablish from '../Register/registerEstablish';
import {
  getRegions,
  getAcademies,
  getTimeSlots,
  registerTeacher,
  getScholarsData
} from '../../services';
import { updateScolarFormAPI, deleteScholarById } from '../../services';
import TakenPopup from '../../components/popups/takenPopup';
import RegisterCollage from '../Register/registerCollage';
import ResponsePopup from '../../components/popups/responsePopup';
import Loader from '../../components/common/loader';
import EditForm from '../../components/form/editForm';
// import Modal from 'react-modal';

const EditScholarForm = () => {
  const [isInfo, setIsInfo] = useState(false);
  const [isMission, setIsMission] = useState(false);
  const [isSlot, setIsSlot] = useState(false);
  const [listSlotes, setListSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [isCollage, setIsCollage] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [regions, setRegions] = useState([]);
  const [academie, setAcademies] = useState([]);
  const [activeResponsible, setActiveResponsible] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSlotSelected, setIsSlotSelected] = useState(false);

  const [displayUser, setDisplayUser] = useState([]);
  const [selecedUser, setSelectedUser] = useState('');
  const [password, setPassword] = useState('');
  const [currentSlot, setCurrentSlot] = useState('');
  const [userID, setUserID] = useState('');

  useEffect(() => {
    // getPageData();
    getRegionsData();
    getAcademiesData();
    setSlotsList();
  }, []);

  useEffect(() => {
    if (academie.length && regions.length) {
      // getFormData();
      getPageData();
    }
  }, [academie, regions]);

  const formik = useFormik({
    initialValues: scolar_initial_value,
    validationSchema: scolar_schema,
    onSubmit: async (values) => {
      const reqData = {
        name: values.schoolData.name,
        address: values.schoolData.address,
        postalCode: values.schoolData.postalCode,
        city: values.schoolData.city,
        typeEstablishment: values.schoolData.type.value,
        region: values.schoolData.region.value,
        academy: values.schoolData.academy.value,
        responsible: values.responsible
      };
      if (password) {
        submitScolarFormData(reqData);
      } else {
        window.alert('Wrong password');
      }
    }
  });

  const passwordAlert = async () => {
    const password = window.prompt(`s'il vous plait entrez votre mot de passe:`, '');
    if (password) {
      setPassword(password);
    }
  };

  const getPageData = async () => {
    try {
      const { data } = await getScholarsData();
      const filterUserData = await filterUser(data);
      setDisplayUser(filterUserData);
      toast.success('Enregistrement réussi ! Merci !');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting the form');
    } finally {
      setIsLoading(false);
    }
  };

  const filterUser = async (resData) => {
    resData.map((item) => {
      item['label'] = item._id;
      item['value'] = item._id;
    });
    return resData;
  };

  const onSelectUserHandler = async (value) => {
    setSelectedUser(value);
    setApiDataInForm(value);
  };

  const handleChange = async (value) => {
    setUserID(value);
    const user = displayUser.find((item) => {
      return item._id === value;
    });
    user ? onSelectUserHandler(user) : setSelectedUser('');
  };

  const getRegionsData = async () => {
    try {
      const { data } = await getRegions();
      const filteredData = data.map(({ _id, name_fr }) => ({
        value: _id,
        label: name_fr
      }));
      setRegions(filteredData);
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  const getAcademiesData = async () => {
    try {
      const { data } = await getAcademies();
      const filteredData = data.map(({ _id, name }) => ({
        value: _id,
        label: name
      }));
      setAcademies(filteredData);
    } catch (e) {
      console.log('Error', e);
    }
  };

  // submit form data to BE
  const submitScolarFormData = async (reqData) => {
    try {
      setIsLoading(true);
      for (const responsible of reqData.responsible) {
        if (responsible.responsibleMissionFuture?.toLowerCase() === 'oui') {
          let body = {
            account: {
              companyName: reqData.name,
              specs: {
                currency: 'EUR'
              },
              addresses: [
                {
                  city: reqData.address,
                  countryId: 'FR',
                  isBilling: true,
                  postcode: reqData.postalCode,
                  street: reqData.region
                }
              ]
            },
            user: {
              firstname: responsible.responsibleFirstName,
              id: responsible.responsibleEmail,
              languageId: 'fr',
              lastname: responsible.responsibleName,
              roles: [],
              salutation: 'mr',
              specs: {}
            }
          };
          try {
            await registerTeacher(body);
          } catch (e) {
            toast.error(`${responsible.responsibleEmail} a déjà un accès futur à la mission`);
          }
        }
      }
      const reqUpdateData = {
        data: {
          userId: selecedUser._id,
          updateData: reqData,
          password: password
        }
      };
      const resposneData = await updateScolarFormAPI(reqUpdateData);
      if (resposneData.status === 200) {
        setSuccess(true);
        formik.resetForm();
        toast.success('Données mises à jour avec succès! Merci !');
        setSelectedUser('');
        setSlotsList();
        getPageData();
        setUserID('');
      } else {
        toast.error('Quelque chose a mal tourné');
      }
    } catch (e) {
      if (e?.response?.status === 409) {
        setAlertData(e.response.data.slot);
        setIsAlert(true);
        toast.error(e.response.data.message);
      } else {
        if (e?.response?.data?.message) {
          toast.error(e.response.data.message);
        } else {
          toast.error(e.message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  // getting the list of slots from BE
  const setSlotsList = async () => {
    try {
      const { data } = await getTimeSlots();
      if (data.length) {
        setListSlots(data);
      } else {
        toast.error('Quelque chose a mal tourné');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const setApiDataInForm = async (data) => {
    const mappedValues = {
      schoolData: {
        name: data.name,
        address: data.address,
        postalCode: data.postalCode,
        city: data.city,
        region: setRegionsDefalutValue(data.region.name),
        academy: setAcademiesDefalutValue(data.academy.name),
        type: {
          label: data.typeEstablishment,
          value: data.typeEstablishment
        }
      },
      responsible: data.responsible
    };
    formik.setValues(mappedValues);
  };

  const setAcademiesDefalutValue = (value) => {
    try {
      let compValue = value.split(' ');
      compValue = compValue[compValue.length - 1];
      const academieValue = academie.find((item) => {
        return item.label.includes(compValue);
      });
      return academieValue;
    } catch (e) {
      return '';
    }
  };

  const setRegionsDefalutValue = (value) => {
    try {
      const compValue = value.replaceAll('-', ' ').toLowerCase();
      const regionsValue = regions.find((item) => {
        return item.label.replaceAll('-', ' ').toLowerCase() === compValue;
      });
      return regionsValue;
    } catch (e) {
      return '';
    }
  };

  // used for getting for which responsible and class we selecting the slot
  const setSelectedSlotData = (data) => {
    setSelectedSlot(data);
    setIsSlot(true);
  };

  // close alert popup
  const handleAlert = () => {
    setIsAlert(false);
  };

  // handle navigation from resiter establish to register collage
  const handleNavigate = () => {
    setIsSlot(false);
    setIsCollage(true);
  };

  // handle back button of slot pages
  const handleBackClick = () => {
    setIsSlot(false);
    setIsCollage(false);
  };

  // set the seleced slot data in form according to responsible and classes
  const setSlotDataInForm = (value) => {
    setIsSlotSelected(true);
    let upDateList = listSlotes;
    upDateList = upDateList.map((item) => {
      if (item.date === value.date && item.type === value.type) {
        item.professions.map((prof) => {
          if (prof.title === value.label) {
            prof.slots.map((ti) => {
              if (ti.time === value.time) {
                ti.slot.map((st) => {
                  if (st.type === value.slot) {
                    st.isBooked = true;
                  }
                  return st;
                });
              }
              return ti;
            });
          }
          return prof;
        });
      }
      return item;
    });
    formik.setFieldValue(
      `responsible[${selectedSlot.responsible}]classes[${selectedSlot.classes}].selectedSlot`,
      value
    );
  };

  // Used for Added More Responsibiles
  const addResponsible = () => {
    const newField = {
      responsibleName: '',
      responsibleFirstName: '',
      responsibleFunction: '',
      responsibleEmail: '',
      responsibleTelephone: '',
      responsibleMissionFuture: '',
      classes: [
        {
          classLevel: '',
          classNumbers: '',
          classVisitor: '',
          classVisitDay: '',
          classLunchSite: '',
          classEstimateTime: ''
        }
      ]
    };
    formik.setFieldValue('responsible', [...formik.values.responsible, newField]);
    const newResponse = formik.values.responsible.length;
    setActiveResponsible(() => newResponse);
  };

  // Used for Added more Classes in the Active Responsibles
  const addClass = () => {
    const newField = {
      classLevel: '',
      classNumbers: '',
      classVisitor: '',
      classVisitDay: '',
      classLunchSite: '',
      classEstimateTime: ''
    };
    formik.setFieldValue(`responsible[${activeResponsible}]classes`, [
      ...formik.values.responsible[activeResponsible].classes,
      newField
    ]);
  };

  const removeResponsible = async (index) => {
    setActiveResponsible(index - 1);
    const updatedData = [...formik.values.responsible];
    updatedData.splice(index, 1);
    formik.setFieldValue(`responsible`, updatedData);
  };

  const removeClass = (resId, classId) => {
    const updatedData = [...formik.values.responsible];
    updatedData[resId].classes.splice(classId, 1);
    formik.setFieldValue(`responsible`, updatedData);
  };

  const deleteUser = async (id) => {
    try {
      const password = window.prompt(`s'il vous plait entrez votre mot de passe:`, '');
      if (password) {
        const reqUpdateData = {
          data: {
            userId: id,
            password: password
          }
        };
        await deleteScholarById(reqUpdateData);
        toast.success('Supprimé avec succès! Merci !');
        setSelectedUser('');
        setSlotsList();
        getPageData();
        setUserID('');
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    }
  };

  return (
    <>
      {!isSlot && !isCollage && (
        <div className="pt-20 md:pt-[200px] bg-white min-h-screen text-[#3130ED] !px-[90px]">
          <div className="flex gap-10 p-4 border-b-[1px] border-[#3130ED] mb-10 ">
            <div className="w-full text-center">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', 'UID'],
                  input: {
                    placeholder: 'Please enter uid',
                    name: 'userID',
                    value: userID,
                    onChange: (e) => handleChange(e.target.value)
                  }
                }}
              />
            </div>
          </div>
          <div className={`container mx-auto bg-white pb-2 !px-[60px] !text-[#3130ED]`}>
            {selecedUser ? (
              <div>
                <div className="flex justify-between">
                  <div>
                    <p>*Les champs marqués par un astérisque sont obligatoires.</p>
                  </div>
                  <div>
                    <button
                      className="rounded-full bg-red-600 text-white w-32 h-7"
                      onClick={() => {
                        deleteUser(selecedUser._id);
                      }}>
                      {' '}
                      Delete
                    </button>
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit} className="block">
                  <div>
                    <div>
                      <EditForm
                        t1={'Responsable'}
                        t2={'CLASSE'}
                        setFieldValue={formik.setFieldValue}
                        schoolFields={formik.values.schoolData}
                        ResponsibleFields={formik.values.responsible}
                        errors={formik.errors}
                        touched={formik.touched}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        selectSlot={setSelectedSlotData}
                        regions={regions}
                        academie={academie}
                        removeClass={removeClass}
                        removeResponsible={removeResponsible}
                        activeResponsible={activeResponsible}
                        setActiveResponsible={setActiveResponsible}
                        setCurrentSlot={setCurrentSlot}
                      />
                    </div>
                  </div>
                  <div className="my-6">
                    <div className="sm:flex lg:mx-4 my-10 gap-5">
                      <div className="sm:w-[40%] w-[60%] mb-4 lg:mb-0 flex items-center sm:justify-center justify-left">
                        <button
                          onClick={() => {
                            passwordAlert();
                          }}
                          className="p-3 w-full text-base rounded-full bg-[#3130ED] text-white">
                          Validez votre inscription
                        </button>
                      </div>
                      <div className="w-[80%] sm:w-[60%] flex items-center justify-left">
                        <p className="p-0">
                          Après avoir validé votre choix, vous recevrez un e-mail de confirmation.
                          Vérifiez votre dossier courrier indésirable.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="bg-[#3130ED] h-0.5 mb-10"></hr>
                  <div className="mb-10">
                    <span
                      onClick={() => addClass()}
                      className="rounded-full w-fit flex gap-2 md:gap-5 items-center mb-3 justify-center bg-[#4b4ced2e] backdrop-blur-[26.5px] p-2 cursor-pointer">
                      <div className="max-w-[13px] flex sm:max-w-[20px]">{plus_icon}</div>
                      <div className="text-xs	md:text-base">
                        Ajouter une classe avec le même responsable
                      </div>
                    </span>
                    <span
                      onClick={() => addResponsible()}
                      className="rounded-full w-fit flex gap-2 md:gap-5 items-center mb-3 justify-center bg-[#4b4ced2e] backdrop-blur-[26.5px] p-2 cursor-pointer">
                      <div className="max-w-[13px] flex sm:max-w-[20px]">{plus_icon}</div>
                      <div className="text-xs	md:text-base">
                        Ajouter une classe avec un nouveau responsable
                      </div>
                    </span>
                  </div>
                </form>
              </div>
            ) : (
              <div className="text-center">Not Found</div>
            )}
          </div>
        </div>
      )}
      {isSlot && (
        <RegisterEstablish
          listSlotes={listSlotes}
          isSlot={isSlot}
          setIsSlot={setIsSlot}
          handleNavigate={handleNavigate}
          selectedSlotData={setSlotDataInForm}
          handleBackClick={handleBackClick}
          currentSlot={currentSlot}
          isVisible={true}
        />
      )}
      {isCollage && (
        <RegisterCollage
          listSlotes={listSlotes}
          isCollage={isCollage}
          setIsCollage={setIsCollage}
          selectedSlotData={setSlotDataInForm}
          handleBackClick={handleBackClick}
          currentSlot={currentSlot}
        />
      )}
      {isInfo && <InfoPopup isInfo={isInfo} setIsInfo={setIsInfo} />}
      {isAlert && <TakenPopup isAlert={isAlert} alertData={alertData} setIsAlert={handleAlert} />}
      {isMission && <MissionPopup isMission={isMission} setIsMission={setIsMission} />}
      {isSuccess && (
        <ResponsePopup
          title={'Enregistrement réussi !'}
          image={'/images/thankyou.png'}
          subText={`Vous recevrez un e-mail de confirmation. Vérifiez votre dossier courrier indésirable.`}
          isCloseButton={true}
          setSuccess={setSuccess}
        />
      )}
      {isSlotSelected && (
        <ResponsePopup
          title={'mERCI POUR VOTRE RÉSERVATION'}
          image={'/images/thankyou.png'}
          subText={`Finalisez votre enregistrement et validez-le à la fin du formulaire.`}
          buttonText={`Retourner à l’enregistrement`}
          isCloseButton={false}
          setSuccess={setIsSlotSelected}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default EditScholarForm;
