import React, { useState } from 'react';
import { cross_icon } from '../../assets/icons';
import ResponsiveVimeoVideo from '../responsiveVimeoVideo/ResponsiveVimeoVideo';

export default function MissionPopup({ isMission, setIsMission, children }) {
  const onCloseClick = () => {
    setIsMission(false);
  };
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="p-4 bg-white w-full lg:w-[1000px] flex flex-col items-center rounded shadow-lg">
        <button
          onClick={() => onCloseClick()}
          className="bg-cblue-500 p-3 w-[64px] w-[84px] rounded-full text-white self-start">
          {cross_icon}
        </button>
        <div className="mx-10 p-4 bg-[#F5F5F5] rounded-md text-[#3130ED] overflow-scroll max-h-[75vh]">
          <div className="flex text-4xl border-b w-fit border-[#3130ED] mb-10">
            <h3 className="pt-2 bold uppercase">préparez votre visite avec</h3>
            <span className="text-3xl flex items-end tracking-[1px] ml-3">mission future</span>
          </div>
          <div className="mb-2">
            <ResponsiveVimeoVideo
              url={
                'https://player.vimeo.com/video/813661574?h=6f8eccf40a&color=ff9933&title=0&byline=0&portrait=0'
              }
            />
          </div>
          <div className="mb-10 ">
            <div className="text-xl mb-8">
              <p>
                Plus bas sur la page d’enregistrement, vous avez la possibilité de vous inscrire au
                Portail Enseignants mission future. Un responsable / enseignant peut gérer plusieurs
                classes.
              </p>
            </div>
            <div className="text-xl mb-8">
              <p className="pb-0">Qu'est-ce que mission future ?</p>
              <p className="pb-0 text-base">
                mission future permet tout d’abord aux élèves d’identifier leurs forces liées à leur
                façon de percevoir et de prendre des décisions. Les élèves sont ensuite mis en
                relation avec des jeunes professionnels qui sont déjà sur la voie du succès dans
                leur métier, en commençant avec les CHAMPIONS WorldSkills France, puis avec les
                Compétiteurs représentant leur région à la Compétition nationale des métiers à Lyon.
              </p>
            </div>
            <div className="text-xl mb-8">
              <p className="pb-0">
                Comment maîtriser le contenu de mission future et l’utiliser en classe ?
              </p>
              <p className="pb-0 text-base">
                Moins d'une heure est nécessaire pour s'approprier l'outil. Des supports et un
                logiciel modernes vous simplifient la préparation de cette découverte des métiers.
              </p>
            </div>
            <div className="text-xl mb-8">
              <p className="pb-0">Notre service pour la rentrée ! </p>
              <p className="pb-0 text-base">
                Nous savons que la rentrée scolaire est une période particulièrement chargée. De ce
                fait, nous mettons à votre disposition mission future dès à présent, pour vous
                permettre une utilisation plus calme et sereine en très peu de temps.
              </p>
              <p className="pb-0 text-base">
                Vous trouverez plus d'informations sur le Portail Enseignants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
