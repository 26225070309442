import React, { useEffect, useState } from 'react';
import NavigateBackButton from '../../components/navigateBackButton/NavigateBackButton';
import RegisterHeading from '../../components/form/RegisterHeading';
import TimeSlot from '../../components/timeSlot';
import ConfirmPopup from '../../components/popups/confirmPopup';
import TakenPopup from '../../components/popups/takenPopup';
import ResponsePopup from '../../components/popups/responsePopup';
import { listData, underLineText, registerEstablishSlots } from '../../data/static';

const RegisterEstablish = ({
  isSlot,
  setIsSlot,
  selectedSlotData,
  listSlotes,
  handleBackClick,
  handleNavigate,
  currentSlot = '',
  isVisible = false
}) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [newList, setNewList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const newList = listSlotes.filter((list) => {
      if (list.type === 'Jeudi' || list.type === 'Vendredi') {
        return list;
      }
    });
    setNewList(newList.sort((a, b) => parseInt(a.date) - parseInt(b.date)));
  }, []);
  const onSlotSelecteHandler = (value) => {
    setSelectedSlot(value);
    setIsConfirm(true);
  };

  const onConfirmSlotsHandler = () => {
    selectedSlotData(selectedSlot);
    setIsConfirm(false);
    setIsSlot(false);
  };

  const onBackClickHandler = () => {
    handleBackClick();
  };

  return (
    <>
      <div className={`container mx-auto bg-white !px-[90px] !text-[#3130ED]`}>
        <div className="flex flex mb-4 sm:mb-9 items-center md:items-baseline pt-[50px] md:pt-[50px]">
          <div className="w-[8%] sm:w-[6%]">
            <NavigateBackButton
              color={'purple'}
              isNavigate={false}
              onBackClickHandler={onBackClickHandler}
            />
          </div>
          <div className="pl-3 md:pl-9">
            <RegisterHeading
              sm={'parcours accompagnés éTABLISSEMENTS HORS COLLèGES DE LA MéTROPOLE DE LYON'}
              lg={'INSCRIPTION'}
            />
          </div>
        </div>
        {isVisible && (
          <div className="mb-2 sm:mb-4">
            <div className="self-stretch text-md md:text-2xl">
              <p className="text-[#8182F2]">
                Si vous êtes un collège de la Métropole de Lyon, merci de cliquer{' '}
                <a
                  onClick={() => {
                    handleNavigate();
                  }}
                  className="underline cursor-pointer">
                  ICI
                </a>
              </p>
            </div>
          </div>
        )}
        {!isVisible && (
          <div className="text-2xl mb-10 text-[#E10F0F]">
            Victime de son succès ! L'ensemble de nos parcours ont été réservés.
            <br /> N’hésitez cependant pas à préparer votre visite à Lyon avec mission future. Ceci
            permettra à vos élèves une découverte sans a priori de la richesse des métiers en
            passant par une association avec les Compétiteurs représentants leur région.
          </div>
        )}
        <div className="mb-2">
          <p className="text-sm md:text-base text-[#8182F2]">
            Vous avez la possibilité de réserver un parcours thématique d'une durée de 1h15 pour
            découvrir 5 métiers en compétition – soit le jeudi 14 septembre soit le vendredi 15
            septembre.
            <br />
            L'itinéraire proposé est accompagné par un guide bénévole qui vous conduira sur les
            différents espaces métiers à la rencontre de professionnels.
            <br />
            Un seul parcours accompagné est possible pour chaque classe.
            <br />
            <br />
            Vous n'avez pas trouvé de créneau disponible et souhaitez être informé des annulations
            de dernière minute ? Vous pouvez transmettre un email à l'adresse
            visite-47worldskills@cofom.org indiquant votre souhait de date et heure de départ du
            parcours, avec dans l'objet de l'email : "Parcours guidé dernière minute". Nous
            reviendrions vers vous si des créneaux de parcours guidés venaient à se libérer (thèmes
            des parcours aléatoires en fonction des annulations).
            <br />
            <br />
            Pour une meilleure qualité de visite, tout groupe supérieur à 17 participants pourra
            éventuellement être séparé en 2 sous-groupes afin de réaliser le parcours réservé.
          </p>
        </div>
        {/* <div>
           <div>
            <RegisterTable />
          </div>
        </div> */}
        <div className="mb-4">
          <div className="bg-[#4b4ced2e] rounded-lg p-3">
            {listData.map((item, inde) => {
              return (
                <div key={inde} className="last:border-0 border-b border-[#3130ed4f] mb-2">
                  <div className="flex">
                    <p className="text-sm !pb-0">
                      <b className="text-lg mr-1">{item.title}</b>
                      {item.value}
                    </p>
                  </div>
                  <p
                    className="text-xs"
                    dangerouslySetInnerHTML={{
                      __html: underLineText(item.text)
                    }}></p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-5">
          <p className="">
            * liste des métiers non définitive - un changement pourra être effectué avant les dates
            de la compétition. <br /> Les <span className="underline">métiers soulignés</span> sont
            des métiers hors compétition.
          </p>
        </div>
        {!isVisible && (
          <div className="self-stretch text-md md:text-xl mb-10">
            <p className="text-[#8182F2] underline">
              Les parcours accompagnés de la Métropole de Lyon seront renseignés séparément.
            </p>
          </div>
        )}
        {isVisible && (
          <div className="my-6">
            {newList.length && (
              <TimeSlot
                title={'Sélectionnez le jour de la visite et le créneau horaire'}
                subTitle={'(Jeudi 14 ou Vendredi 15 Septembre)'}
                timeSlot={registerEstablishSlots}
                onSelectHandler={onSlotSelecteHandler}
                listSlotes={newList}
                currentSlot={currentSlot}
              />
            )}
          </div>
        )}
      </div>
      {isConfirm && (
        <ConfirmPopup
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          confirmSlot={onConfirmSlotsHandler}
          data={selectedSlot}
        />
      )}
    </>
  );
};

export default RegisterEstablish;
