export const registerEstablishSlots = [
  {
    id: 0,
    date: 14,
    day: 'Jeudi',
    fullDay: `14 Septembre 2023 (Jeudi)`,
    data: [
      {
        label: `Les métiers d'Art`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Les métiers de l'artisanat`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Innovation technologique`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Développement durable`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Du jardin à l'assiette`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `La science et la santé`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Entretien et réparation`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Mixité dans les métiers`,
        showTime: [`12h45 - 14h15`]
      }
    ]
  },
  {
    id: 1,
    date: 15,
    day: 'Vendredi',
    fullDay: `15 Septembre 2023 (Vendredi)`,
    data: [
      {
        label: `Les métiers d'Art`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Les métiers de l'artisanat`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Innovation technologique`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Développement durable`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Du jardin à l'assiette`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `La science et la santé`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Entretien et réparation`,
        showTime: [`12h00 - 13h30`, `12h15 - 13h45`, `12h30 - 14h00`, `12h45 - 14h15`]
      },
      {
        label: `Mixité dans les métiers`,
        showTime: [`12h00 - 13h30`, `12h45 - 14h15`]
      }
    ]
  }
];

export const registerCollageSlots = [
  {
    id: 0,
    date: 14,
    day: 'Jeudi Matin',
    fullDay: `14 Septembre 2023 (Jeudi Matin)`,
    data: [
      {
        label: `Les métiers d'Art`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Les métiers de l'artisanat`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Innovation technologique`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Développement durable`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Du jardin à l'assiette`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `La science et la santé`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Entretien et réparation`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Mixité dans les métiers`,
        showTime: [`12h00 - 13h30`, `12h45 - 14h15`]
      }
    ]
  },
  {
    id: 1,
    date: 14,
    day: 'Jeudi Après-midi',
    fullDay: `14 Septembre 2023 (Jeudi Après-midi)`,
    data: [
      {
        label: `Les métiers d'Art`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Les métiers de l'artisanat`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Innovation technologique`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Développement durable`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Du jardin à l'assiette`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `La science et la santé`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Entretien et réparation`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Mixité dans les métiers`,
        showTime: [`14h00 - 15h30`, `14h45 - 16h15`]
      }
    ]
  },
  {
    id: 2,
    date: 15,
    day: 'Vendredi Matin',
    fullDay: `15 Septembre 2023 (Vendredi Matin)`,
    data: [
      {
        label: `Les métiers d'Art`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Les métiers de l'artisanat`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Innovation technologique`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Développement durable`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Du jardin à l'assiette`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `La science et la santé`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Entretien et réparation`,
        showTime: [`10h00 - 11h30`, `10h15 - 11h45`, `10h30 - 12h00`, `10h45 - 12h15`]
      },
      {
        label: `Mixité dans les métiers`,
        showTime: [`12h00 - 13h30`, `12h45 - 14h15`]
      }
    ]
  },
  {
    id: 3,
    date: 15,
    day: 'Vendredi Après-midi',
    fullDay: `15 Septembre 2023 (Vendredi Après-midi)`,
    data: [
      {
        label: `Les métiers d'Art`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Les métiers de l'artisanat`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Innovation technologique`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Développement durable`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Du jardin à l'assiette`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `La science et la santé`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Entretien et réparation`,
        showTime: [`14h00 - 15h30`, `14h15 - 15h45`, `14h30 - 16h00`, `14h45 - 16h15`]
      },
      {
        label: `Mixité dans les métiers`,
        showTime: [`14h00 - 15h30`, `14h45 - 16h15`]
      }
    ]
  }
];

export const listData = [
  {
    title: `L'art au service des métiers`,
    value: `- Métiers d’art et l’art au service des métiers`,
    text: `(Pâtisserie-confiserie, Bijouterie-joaillerie, Taille de pierre, Soins esthétiques, Arts graphique et pré-presse)*`
  },
  {
    title: `Les métiers de l’art et de l'artisanat`,
    value: `- Un savoir-faire particulier pour transformer des produits ou des services`,
    text: `(Ebénisterie, Maroquinerie, Coiffure, Boucherie, Chaudronnerie)*`
  },
  {
    title: `Innovation technologique`,
    value: `- Les bonnes pratiques dans les métiers : économie circulaire, recyclage, eau, réduction de l’impacts carbone, énergie, matériaux, éco-conception…`,
    text: `(Challenge entrepreneurial en équipe, Jardinier-paysagiste, Cycle, Fabrication additive, Aménagement 
      urbain et réseaux de canalisation)*`
  },
  {
    title: `Développement durable`,
    value: `- Des métiers faisant intervenir des innovations technologiques`,
    text: `(Electronique, Technologie automobile, Construction digitale, Solutions logicielles en entreprise, Miroiterie)*`
  },
  {
    title: `Du jardin à l'assiette`,
    value: `- Les métiers au service de notre alimentation`,
    text: `(Horticulture, Maintenance des matériels, Cuisine, Service en restauration, //Fromager//)*`
  },
  {
    title: `La science et la santé`,
    value: `- Les métiers au service de notre santé`,
    text: `(Soins infirmiers, Horticulture, Cloud computing, Robotique mobile, //Educateur sportif//)*`
  },
  {
    title: `Fabrication`,
    value: `- La fabrication au cœur des métiers`,
    text: `(Mode et création, Fraisage, Boulangerie, Visual merchandising, Métallerie)*`
  },
  {
    title: `Les couleurs dans les métiers`,
    value: `- L’importance de la couleur dans ces métiers`,
    text: `(Sommellerie, Imprimerie, Peinture automobile, Peinture et décoration, Art floral)*`
  },
  {
    title: `Entretien et réparation`,
    value: `- Entretenir et réparer pour éviter le gaspillage`,
    text: `(Plomberie et chauffage, Tôlerie-carrosserie, Maintenance aéronautique, Métiers de la propreté, Soudage)*`
  },
  {
    title: `Mixité dans les métiers (avec la Fondation JAE)`,
    value: ``,
    text: ``
  }
];

export const underLineText = (text) => {
  const newArr = text.split('//');
  if (newArr.length > 1) {
    let newStr = newArr[0];
    newStr = `${newStr} <span style='text-decoration: underline'> ${newArr[1]} </span> ${newArr[2]}`;
    return newStr;
  } else {
    return text;
  }
};
