import React, { useState, useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import FormBG from '../../components/form/formBg';
import Input from '../../components/form/Input';
import Radio from '../../components/form/Radio';

import { stringArrayToOptionsList } from '../../util/helpers';

import { form_2_schema, form_2_initial_values } from '../../data/form_2.schema';
import regionsList from '../../data/regions.list';
import academieList from '../../data/academie.list';
import typeList from '../../data/type.list';

import { SubmitForm_2, digitalAPI } from '../../services/formSubmit';

import NavigateBackButton from '../../components/navigateBackButton/NavigateBackButton';
import FormHeading from '../../components/form/FormHeading';

const Form_2 = ({ setHeaderType }) => {
  setHeaderType();

  const [showTypeEstOtherInput, setShowTypeEstOtherInput] = useState(false);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: form_2_initial_values,
    validationSchema: form_2_schema,
    onSubmit: async ({
      type_establishment: { value: type_establishment } = {},
      academie_establishment: { value: academie_establishment },
      region_establishment: { value: region_establishment },
      firstName,
      lastName,
      email,
      ...values
    }) => {
      try {
        await SubmitForm_2({
          type_establishment,
          academie_establishment,
          region_establishment,
          firstName,
          lastName,
          email,
          ...values
        });
        await digitalAPI({
          type_establishment,
          academie_establishment,
          region_establishment,
          firstName,
          lastName,
          email,
          ...values
        });
        toast.success('Enregistrement réussi ! Merci !', {
          position: 'bottom-center',
          autoClose: 20000
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    }
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FormBG />
      <div className={`container mx-auto pt-20 md:pt-[200px]`}>
        <NavigateBackButton color={'white'} />
        <FormHeading />
        <form onSubmit={handleSubmit} className="block my-20">
          <div className="grid grid-rows-1 md:grid-cols-2 gap-y-6 gap-x-12">
            <span className="block">
              <Input
                data={{
                  label: ['Nom', 'de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'nom_establishment',
                    value: values.nom_establishment,
                    touched: touched.nom_establishment,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.nom_establishment &&
                errors.nom_establishment !== '' &&
                errors.nom_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.nom_establishment}</p>
                )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['ADresse', 'de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'addresse_establishment',
                    value: values.addresse_establishment,
                    touched: touched.addresse_establishment,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.addresse_establishment &&
                errors.addresse_establishment !== '' &&
                errors.addresse_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.addresse_establishment}</p>
                )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['CoDe', 'postal de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'code_establishment',
                    value: values.code_establishment,
                    touched: touched.code_establishment,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.code_establishment &&
                errors.code_establishment !== '' &&
                errors.code_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.code_establishment}</p>
                )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['VIlLE', 'de l’établissement*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'ville_establishment',
                    value: values.ville_establishment,
                    touched: touched.ville_establishment,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.ville_establishment &&
                errors.ville_establishment !== '' &&
                errors.ville_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.ville_establishment}</p>
                )}
            </span>
            <span className="block">
              <Input
                type={'select'}
                data={{
                  label: ['RégIon', 'de l’établissement*'],
                  input: {
                    options: stringArrayToOptionsList(regionsList),
                    placeholder: 'Sélectionner',
                    name: 'region_establishment',
                    value: values.region_establishment,
                    touched: touched.region_establishment,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('region_establishment', val);
                    }
                  }
                }}
              />
              {touched.region_establishment &&
                errors.region_establishment !== '' &&
                errors.region_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.region_establishment}</p>
                )}
            </span>
            <span className="block">
              <Input
                type={'select'}
                data={{
                  label: ['TyPE', 'de l’établissement*'],
                  input: {
                    options: stringArrayToOptionsList(typeList),
                    placeholder: 'Sélectionner',
                    name: 'type_establishment',
                    value: values.type_establishment,
                    touched: touched.type_establishment,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('type_establishment', val);
                      if (val.value && val.value === 'Autres') {
                        setShowTypeEstOtherInput(true);
                      } else {
                        setShowTypeEstOtherInput(false);
                      }
                    }
                  }
                }}
              />
              {touched.type_establishment &&
                errors.type_establishment !== '' &&
                errors.type_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.type_establishment}</p>
                )}

              {showTypeEstOtherInput && (
                <Input
                  type={'small_input'}
                  data={{
                    label: ['SPÉCIFIER LE TYPE DE L’ÉTABLISSEMENT'],
                    input: {
                      placeholder: 'écrire ici',
                      name: 'type_establishment_other',
                      value: values.type_establishment_other,
                      touched: touched.type_establishment_other,
                      onBlur: handleBlur,
                      onChange: handleChange
                    }
                  }}
                />
              )}
            </span>
            <span className="block">
              <Input
                type={'select'}
                data={{
                  label: ['Académie', 'de l’établissement*'],
                  input: {
                    options: stringArrayToOptionsList(academieList),
                    placeholder: 'Sélectionner',
                    name: 'academie_establishment',
                    value: values.academie_establishment,
                    touched: touched.academie_establishment,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('academie_establishment', val);
                    }
                  }
                }}
              />
              {touched.academie_establishment &&
                errors.academie_establishment !== '' &&
                errors.academie_establishment !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.academie_establishment}</p>
                )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['NoMBRe', 'approximatif d’élèves prévus *'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'nombre_approximate',
                    value: values.nombre_approximate,
                    touched: touched.nombre_approximate,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.nombre_approximate &&
                errors.nombre_approximate !== '' &&
                errors.nombre_approximate !== undefined && (
                  <p className="text-red-400 text-xs uppercase">{errors.nombre_approximate}</p>
                )}
            </span>
          </div>
          <p className="text-base uppercase my-14">
            <span className="font-wskf text-3xl pr-1 tracking-[2px] normal-case">EnsEIgnanT</span>
          </p>
          <div className="grid grid-rows-1 md:grid-cols-2 gap-y-6 gap-x-12">
            <span className="block">
              <Input
                data={{
                  label: ['Nom*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'firstName',
                    value: values.firstName,
                    touched: touched.firstName,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.firstName && errors.firstName !== '' && errors.firstName !== undefined && (
                <p className="text-red-400 text-xs uppercase">{errors.firstName}</p>
              )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['PRénoM*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'lastName',
                    value: values.lastName,
                    touched: touched.lastName,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.lastName && errors.lastName !== '' && errors.lastName !== undefined && (
                <p className="text-red-400 text-xs uppercase">{errors.lastName}</p>
              )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['FoNCTIoN*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'function',
                    value: values.function,
                    touched: touched.function,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.function && errors.function !== '' && errors.function !== undefined && (
                <p className="text-red-400 text-xs uppercase">{errors.function}</p>
              )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['EmaIL*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'email',
                    value: values.email,
                    touched: touched.email,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.email && errors.email !== '' && errors.email !== undefined && (
                <p className="text-red-400 text-xs uppercase">{errors.email}</p>
              )}
            </span>
            <span className="block">
              <Input
                data={{
                  label: ['TéLépHoNe*'],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'telephone',
                    value: values.telephone,
                    touched: touched.telephone,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {touched.telephone && errors.telephone !== '' && errors.telephone !== undefined && (
                <p className="text-red-400 text-xs uppercase">{errors.telephone}</p>
              )}
            </span>

            <span className="block mt-10 md:col-span-2">
              <p className="text-base md:text-2xl uppercase mb-6">
                <span className="font-wskf tracking-[2px] text-3xl md:text-4xl pr-1">
                  InTerVIeW VIsIo aVEC un CHAMPION
                </span>
                <span className="block">
                  Aimeriez-vous interviewer un Champion ou un Ambassadeur WorldSkills France en
                  visio avec votre classe (environ 20 minutes) ?
                </span>
                <span className="block">
                  Le nombre des interviews est limité (l’ordre des inscriptions est déterminant) !
                </span>
              </p>
              <span className="block">
                <Radio
                  extraClass={'max-w-[200px]'}
                  data={{
                    label: ['OuI'],
                    name: 'interview',
                    value: true,
                    onChange: (val) => setFieldValue('interview', val.target.defaultValue)
                  }}
                />
                <Radio
                  extraClass={'max-w-[200px]'}
                  data={{
                    label: ['NoN'],
                    name: 'interview',
                    value: false,
                    onChange: (val) => setFieldValue('interview', val.target.defaultValue)
                  }}
                />
              </span>
            </span>
            <span className="block mt-10 md:col-span-2">
              <p className="text-base md:text-2xl uppercase">
                <span className="font-wskf tracking-[2px] text-3xl md:text-4xl pr-1 normal-case">
                  SI OUI,
                </span>{' '}
                quel jour cette interview serait souhaitée ?
                <span className="block">Notez tous les jours possibles svp !</span>
              </p>
              <span className="block">
                <Radio
                  extraClass={'max-w-[340px]'}
                  data={{
                    label: ['LundI', '18 SEPTEMBRE 2023'],
                    name: 'interview_day',
                    value: '18 SEPTEMBRE 2023',
                    onChange: (val) => setFieldValue('interview_day', val.target.defaultValue)
                  }}
                />
                <Radio
                  extraClass={'max-w-[340px]'}
                  data={{
                    label: ['MarDI', '19 SEPTEMBRE 2023'],
                    name: 'interview_day',
                    value: '19 SEPTEMBRE 2023',
                    onChange: (val) => setFieldValue('interview_day', val.target.defaultValue)
                  }}
                />
                <Radio
                  extraClass={'max-w-[340px]'}
                  data={{
                    label: ['MeRCRedI', '20 SEPTEMBRE 2023'],
                    name: 'interview_day',
                    value: '20 SEPTEMBRE 2023',
                    onChange: (val) => setFieldValue('interview_day', val.target.defaultValue)
                  }}
                />
                <Radio
                  extraClass={'max-w-[340px]'}
                  data={{
                    label: ['JeuDI', '21 SEPTEMBRE 2023'],
                    name: 'interview_day',
                    value: '21 SEPTEMBRE 2023',
                    onChange: (val) => setFieldValue('interview_day', val.target.defaultValue)
                  }}
                />
                <Radio
                  extraClass={'max-w-[340px]'}
                  data={{
                    label: ['VenDReDI', '22 SEPTEMBRE 2023'],
                    name: 'interview_day',
                    value: '22 SEPTEMBRE 2023',
                    onChange: (val) => setFieldValue('interview_day', val.target.defaultValue)
                  }}
                />
              </span>
            </span>

            <span className="block mt-10">
              <button
                disabled={isSubmitting}
                type="submit"
                className="w-full md:w-auto text-lyon-blue bg-white rounded-full px-10 py-1 pt-3 tracking-[2px] font-wskf text-4xl font-400">
                VaLIDaTIon
              </button>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form_2;
