import React from 'react';
import Select from 'react-select';
import { select_Icon } from '../../assets/icons';

export default function Input({ type, data, theme }) {
  return type === 'select' ? (
    <div className="mb-2 relative">
      {data.label && (
        <label className={`block text-base mb-2 ${theme === 'white' ? '' : 'uppercase'}`}>
          <span
            className={`font-wskf text-3xl ${
              theme === 'white' ? '' : 'pr-1 tracking-[2px]'
            } normal-case`}>
            {data.label[0]}
          </span>
          {data.label[1] && <span>{data.label[1]}</span>}
          {data.label[2] && (
            <p className={`mt-2 ${theme === 'white' ? '!pb-0' : ''}`}>{data.label[2]}</p>
          )}
        </label>
      )}
      <Select
        classNamePrefix={'custom_select'}
        className={`${
          theme === 'white' ? 'border-[#3130ED] select_set_color' : 'bg-transparent border-white'
        } appearance-none border-2 rounded-full w-full text-white placeholder-lyon-placeholder_purple focus:outline-none focus:shadow-outline`}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '45px'
          })
        }}
        {...data.input}
        placeholder={data?.input?.placeholder ?? 'Sélectionner'}
      />
      {theme === 'white' && <span className="absolute top-11 right-3">{select_Icon}</span>}
    </div>
  ) : type === 'small_input' ? (
    <div className="mb-2 mt-4">
      <label className="block text-xs uppercase">
        <span className="pr-1">{data.label[0]}</span>
        {data.label[1] && <span>{data.label[1]}</span>}
        {data.label[2] && (
          <p className={`mt-2 ${theme === 'white' ? '!pb-0' : ''}`}>{data.label[2]}</p>
        )}
      </label>
      <input
        className="bg-transparent appearance-none border-lyon-gray border-b-2 w-full py-2 text-white placeholder-lyon-placeholder_purple focus:outline-none focus:shadow-outline"
        {...data.input}
      />
    </div>
  ) : (
    <div className="mb-2">
      <label className={`block text-base mb-2 ${theme === 'white' ? '' : 'uppercase'}`}>
        <span
          className={`font-wskf text-3xl ${
            theme === 'white' ? '' : 'pr-1 tracking-[2px]'
          } normal-case`}>
          {data.label[0]}
        </span>
        {data.label[1] && <span>{data.label[1]}</span>}
        {data.label[2] && (
          <p className={`mt-2 ${theme === 'white' ? '!pb-0' : ''}`}>{data.label[2]}</p>
        )}
      </label>
      <input
        className={`${
          theme === 'white' ? 'border-[#3130ED] text-[#3130ED]' : 'border-white text-white'
        } bg-transparent appearance-none border-2 rounded-full w-full py-2 px-4 placeholder-lyon-placeholder_purple focus:outline-none focus:shadow-outline`}
        {...data.input}
      />
    </div>
  );
}
