import React, { useEffect } from 'react';

// Sections
import Hero from './sections/hero';
import Video from './sections/video';
import Comment from './sections/comment';
import Options from './sections/options';

const LyonLandingPage = ({ setHeaderType }) => {
  setHeaderType();

  useEffect(() => {
    window.location.href =
      'https://www.inscriptions-groupes.competition-nationale-des-metiers.org/';
  }, []);

  return (
    <div className={`container mx-auto pt-20 md:pt-[200px]`}>
      <Hero />
      <Video />
      <Comment />
      <Options />
    </div>
  );
};

export default LyonLandingPage;
