import React, { useState } from 'react';
import { cross_icon } from '../../assets/icons';

export default function ResponsePopup({
  title,
  image,
  subText,
  buttonText,
  isCloseButton = true,
  setSuccess
}) {
  const onClickHandler = (value) => {
    setSuccess(false);
  };

  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="p-4 relative bg-white w-full lg:w-[800px] flex flex-col items-center rounded-lg shadow-lg">
        <div className="mx-10 p-4 rounded-md text-[#3130ED] overflow-scroll max-h-[75vh] flex justify-center items-center">
          {isCloseButton && (
            <button
              onClick={() => onClickHandler('close')}
              className="bg-cblue-500 absalote top-4 left-6 p-3 w-[72px] absolute rounded-full text-white self-start">
              {cross_icon}
            </button>
          )}
          <div>
            <div className="flex flex-col justify-center items-center">
              <div className="text-3xl flex justify-center items-center uppercase">{title}</div>
              <div className="w-[100%]">
                <div className="w-full pt-4 mb-9 px-8 border-b-[1px] border-[#3130ED] block"></div>
              </div>
            </div>
            <div className="flex justify-center items-center mb-4">
              {image && <img src={image} className="w-[210px]" />}
            </div>
            <div className="flex justify-center items-center">
              <p>{subText}</p>
            </div>
            <div className="flex justify-center items-center">
              {buttonText && (
                <button
                  className="bg-[#3130ED] text-white rounded-full px-5 py-1"
                  onClick={() => onClickHandler('submit')}>
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
