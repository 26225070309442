import React from 'react';

import NavigateBackButton from '../../components/navigateBackButton/NavigateBackButton';
import ResponsiveVimeoVideo from '../../components/responsiveVimeoVideo/ResponsiveVimeoVideo';
import DigitalForm from '../../components/form/digitalForm';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { digital_initial_value, digital_schema } from '../../data/scholar_form';

import { registerTeacher } from '../../services';
import { submitDigitalApi } from '../../services/formSubmit';
import DigitalHeading from '../../components/digitalHeading';

const DigitalParticipant = () => {
  const handleSubmitForm = async ({
    name,
    address,
    postalCode,
    city,
    region,
    academy,
    type,
    responsibleFirstName,
    responsibleLastName,
    responsibleFunction,
    responsibleEmail,
    responsibleTelephone
  }) => {
    try {
      let body = {
        account: {
          companyName: name,
          specs: {
            currency: 'EUR'
          },
          addresses: [
            {
              city: address,
              countryId: 'FR',
              isBilling: true,
              postcode: postalCode,
              street: region.value
            }
          ]
        },
        user: {
          firstname: responsibleFirstName,
          id: responsibleEmail,
          languageId: 'fr',
          lastname: responsibleLastName,
          roles: [],
          salutation: 'mr',
          specs: {}
        }
      };

      let digitalReqBody = {
        name,
        address,
        postalCode,
        city,
        region: region.label,
        academy: academy.label,
        type: type.value,
        responsibleFirstName,
        responsibleLastName,
        responsibleFunction,
        responsibleEmail,
        responsibleTelephone
      };

      await registerTeacher(body);
      await submitDigitalApi(digitalReqBody);
      toast.success('Enregistrement réussi ! Merci !');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting the form');
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: digital_initial_value,
    validationSchema: digital_schema,
    onSubmit: handleSubmitForm
  });

  return (
    <>
      <div className={`container mx-auto bg-white pb-2 !px-[90px] !text-[#3130ED]`}>
        <form onSubmit={handleSubmit} id="digitalform" className="block">
          <div className="flex items-center mb-0 sm:mb-9 mb-sm-4 pt-[50px] md:pt-[100px]">
            <div className="w-[8%] md:w-[6%]">
              <NavigateBackButton color={'purple'} />
            </div>
            <div className="pl-3 md:pl-9 w-full lg:w-[80%] ">
              <DigitalHeading
                sm={'mission future -'}
                lg={'L’EXPéRIENCE NUMéRIQUE'}
                isBorder={false}
                sub={`inscrivez votre classe / groupe pour profiter <br/>
              à distance de la compétition nationale des métiers`}
              />
            </div>
          </div>
          <div className="w-full border-b border-[#3130ED] mb-10"></div>
          <div className="md:mb-2 mb-0 ">
            <div className="self-stretch text-sm md:text-lg">
              <p>
                Tout au long de l’automne, le programme numérique « mission future » permettra à
                votre classe de profiter à distance des émotions vécues lors de la Compétition
                nationale des métiers.
              </p>
              <p>
                « mission future » vous invite à démarrer l’année scolaire avec une activité
                favorisant la cohésion de la classe, tout d’abord en offrant aux élèves la
                possibilité d’identifier leurs forces en lien avec leur façon de percevoir et de
                prendre des décisions. L’outil établit ensuite une correspondance entre les élèves
                et de jeunes professionnels étant déjà sur la voie du succès dans leur métier ; ceci
                d’abord avec les CHAMPIONS WorldSkills France, puis avec les Compétiteurs
                représentant leur région lors de la Compétition nationale des métiers 2023.
              </p>
              <p>
                L’identification avec les protagonistes de chaque région sera soutenue par une série
                de courtes vidéos mises à disposition sur la page internet de chaque équipe
                régionale.
              </p>
            </div>
          </div>
          <div className="mb-10">
            <ResponsiveVimeoVideo
              url={
                'https://player.vimeo.com/video/813661574?h=6f8eccf40a&color=ff9933&title=0&byline=0&portrait=0'
              }
            />
          </div>
          <div>
            <div className="mb-8">
              <p>
                Tous ces éléments du programme seront fournis afin de vous permettre de les utiliser
                rapidement et sans grand effort supplémentaire jusqu’à la fin de l’année 2023.
              </p>
              <p>
                En remplissant le formulaire d'inscription ci-dessous, vous vous assurez de recevoir
                toutes les informations nécessaires en temps voulu.
              </p>
              <p>
                Durant le mois de septembre 2023 des webinaires tutoriels de 30 minutes sont mis en
                place, pour vous permettre une prise en main facile et efficace de l’outil. Le lien
                d’inscription pour les webinaires se trouve sur le mail de confirmation que vous
                recevrez après votre inscription.
              </p>
            </div>
          </div>
          <div>
            <div>
              <DigitalForm
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
          <div className="my-6">
            <div className="sm:flex lg:mx-4 my-10 gap-5">
              <div className="sm:w-[40%] w-[60%] mb-4 lg:mb-0 flex items-center sm:justify-center justify-left">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="p-3 w-full text-base rounded-full bg-[#3130ED] text-white">
                  Enregistrement
                </button>
              </div>
              <div className="w-[80%] sm:w-[60%] flex items-center justify-left">
                <p className="p-0">
                  Après avoir cliqué sur Enregistrement, vous
                  <br /> recevrez un e-mail de confirmation. <br /> Vérifiez votre dossier courrier
                  indésirable.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <p>
              NOTE : mission future a été conçu à l’origine pour les collèges (5/4/3ème), mais est
              mis disposition de tous types de groupes (scolaires ou non) dans le cadre de la
              Compétition nationale des métiers. L’outil est validé pour les personnes de 12 ans et
              plus.
            </p>
          </div>
          <div className="w-full border-b border-[#3130ED] mb-10"></div>
          <div className="mb-10">
            {/* <div>
              <p className="text-lg md:text-2xl pb-0">
                AUTRES OPPORTUNITÉS NUMÉRIQUES
              </p>
              <p className="text-sm md:text-base tracking-[1px]">
                WorldSkills France prévoit une large couverture numérique de la
                Compétition nationale des métiers. En cliquant sur le bouton
                Couverture numérique ci-dessous, vous obtiendrez par e-mail
                toutes les informations à ce sujet durant le courant de l’été.
              </p>
            </div>
            <div>
              <button
                form="class1"
                type="submit"
                className="p-3 px-10 w-fit text-base rounded-full bg-[#3130ED] text-white">
                Couverture numérique
              </button>
            </div> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default DigitalParticipant;
