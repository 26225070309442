import React from 'react';

const DigitalHeading = ({ sm, lg, isBorder = true, sub = false }) => {
  return (
    <>
      <div
        className={`${
          isBorder ? 'justify-start' : 'justify-start'
        } w-full mx-auto flex gap-4 flex-wrap justify-center`}>
        <div
          className={`w-auto block text-[#3130ED] flex-row items-center gap-0 sm:gap-2 border-[#3130ED]`}>
          <b className="relative sm:text-2xl lg:text-3xl xl:text-3xl pb-0 uppercase">{sm}</b>
          <span className="lg:text-5xl md:text-4xl text-2xl font-wskf ml-0 md:ml-[10px] tracking-[1px] md:tracking-normal">
            {lg}
          </span>
        </div>
      </div>
      {sub && (
        <div
          className="text-xs sm:text-lg md:text-2xl uppercase mt-3 sm:mt-10 flex items-center text-center justify-center"
          dangerouslySetInnerHTML={{
            __html: sub
          }}></div>
      )}
    </>
  );
};

export default DigitalHeading;
