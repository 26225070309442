import React from 'react';

const FormHeading = () => {
  return (
    <div className="mt-4 mb-20 w-full mx-auto flex justify-center items-center gap-4">
      <div className="w-auto inline-block">
        <p className="relative text-lg md:text-2xl lg:text-3xl xl:text-4xl pb-0 uppercase">
          UNe viTRine poUr découvrir près de
          <span className="block border-b-[1px] my-1 md:my-2 lg:my-3"></span>
          <span className="absolute right-0 top-[85%] text-right w-auto inline-block md:hidden">
            <span className="block bg-lyon-purple pl-2 md:bg-transparent font-wskf tracking-[2px] text-3xl md:text-4xl lg:text-5xl xl:text-6xl normal-case pb-0 default_line_height">
              70 méTieRs
            </span>
          </span>
          <span className="absolute right-0 top-[170%] text-right w-auto inline-block md:hidden">
            <span className="block text-lg md:text-2xl lg:text-3xl xl:text-4xl pb-0 uppercase default_line_height">
              en situation réelle
            </span>
          </span>
        </p>
      </div>
      <div className="hidden md:static w-auto md:inline-block">
        <p className="bg-lyon-purple md:bg-transparent font-wskf tracking-[2px] text-3xl md:text-4xl lg:text-5xl xl:text-6xl normal-case pb-0 default_line_height">
          70 méTieRs
        </p>
        <p className="text-xl md:text-2xl lg:text-3xl xl:text-4xl pb-0 uppercase default_line_height">
          en situation réelle
        </p>
      </div>
    </div>
  );
};

export default FormHeading;
