import React, { useEffect, useContext, useState } from 'react';
import NavigateBackButton from '../../components/navigateBackButton/NavigateBackButton';
import RegisterHeading from '../../components/form/RegisterHeading';
import TimeSlot from '../../components/timeSlot';
import { listData, underLineText, registerCollageSlots } from '../../data/static';
import ConfirmPopup from '../../components/popups/confirmPopup';

const RegisterCollage = ({
  isCollage,
  setIsCollage,
  selectedSlotData,
  handleBackClick,
  listSlotes,
  currentSlot = ''
}) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [newList, setNewList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const newList = listSlotes.filter((list) => {
      if (list.type === 'Jeudi' || list.type === 'Vendredi') {
      } else {
        return list;
      }
    });

    const morning14 = newList.filter((list) => list.date === '14' && list.type === 'Jeudi Matin');
    const evening14 = newList.filter(
      (list) => list.date === '14' && list.type === 'Jeudi Après-midi'
    );

    const morning15 = newList.filter(
      (list) => list.date === '15' && list.type === 'Vendredi Matin'
    );
    const evening15 = newList.filter(
      (list) => list.date === '15' && list.type === 'Vendredi Après-midi'
    );

    setNewList([morning14[0], evening14[0], morning15[0], evening15[0]]);
  }, []);

  const onSlotSelecteHandler = (value) => {
    setSelectedSlot(value);
    setIsConfirm(true);
  };

  const onBackClickHandler = () => {
    handleBackClick();
  };

  const onConfirmSlotsHandler = () => {
    selectedSlotData(selectedSlot);
    setIsConfirm(false);
    setIsCollage(false);
  };

  return (
    <>
      <div className={`container mx-auto bg-white !px-[90px] !text-[#3130ED]`}>
        <div className="flex flex mb-4 sm:mb-9 items-center md:items-baseline pt-[50px] md:pt-[50px]">
          <div className="w-[8%] sm:w-[6%]">
            <NavigateBackButton
              color={'purple'}
              isNavigate={false}
              onBackClickHandler={onBackClickHandler}
            />
          </div>
          <div className="pl-9">
            <RegisterHeading
              sm={'PARCOURS ACCOMPAGNÉS collèges de la Métropole de lyon '}
              lg={'INSCRIPTION'}
            />
          </div>
        </div>
        <div className="mb-2 sm:mb-4">
          <div className="self-stretch text-md md:text-2xl">
            <p className="text-[#8182F2]">
              Pour les collèges de la Métropole de Lyon, une série de Parcours Accompagnés a été
              mise en place. L'inscription ci-dessous n'est ouverte qu'à ces collèges
            </p>
          </div>
        </div>
        <div className="mb-2">
          <p className="text-sm md:text-base text-[#8182F2]">
            Vous avez la possibilité de réserver un parcours thématique d'une durée de 1h15 pour
            découvrir 5 métiers en compétition – soit le jeudi 14 septembre soit le vendredi 15
            septembre.
          </p>
          <p className="text-base text-[#8182F2]">
            L'itinéraire proposé est accompagné par un guide bénévole qui vous conduira sur les
            différents espaces métiers à la rencontre de professionnels.
            <br />
            Un seul parcours accompagné est possible pour chaque classe.
            <br />
            <br />
            Pour une meilleure qualité de visite, tout groupe supérieur à 17 participants pourra
            éventuellement être séparé en 2 sous-groupes afin de réaliser le parcours réservé.
          </p>
        </div>
        {/* <div>
           <div>
            <RegisterTable />
          </div>
        </div> */}
        <div className="mb-4">
          <div className="bg-[#4b4ced2e] rounded-lg p-3">
            {listData.map((item, inde) => {
              return (
                <div key={inde} className="last:border-0 border-b border-[#3130ed4f] mb-2">
                  <div className="flex">
                    <p className="text-sm !pb-0">
                      <b className="text-lg mr-1">{item.title}</b>
                      {item.value}
                    </p>
                  </div>
                  <p
                    className="text-xs"
                    dangerouslySetInnerHTML={{
                      __html: underLineText(item.text)
                    }}></p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-5">
          <p className="">
            * liste des métiers non définitive - un changement pourra être effectué avant les dates
            de la compétition. <br /> Les <span className="underline">métiers soulignés</span> sont
            des métiers hors compétition.
          </p>
        </div>
        <div className="my-6">
          {newList.length && (
            <TimeSlot
              title={'Sélectionnez le jour de la visite et le créneau horaire'}
              subTitle={'(Jeudi 14 ou Vendredi 15 Septembre)'}
              timeSlot={registerCollageSlots}
              onSelectHandler={onSlotSelecteHandler}
              listSlotes={newList}
              currentSlot={currentSlot}
            />
          )}
        </div>
      </div>
      {isConfirm && (
        <ConfirmPopup
          isConfirm={isConfirm}
          setIsConfirm={setIsConfirm}
          confirmSlot={onConfirmSlotsHandler}
          data={selectedSlot}
        />
      )}
    </>
  );
};

export default RegisterCollage;
