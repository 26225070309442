import React from 'react';

const RegisterHeading = ({ sm, lg, isBorder = true, sub = false }) => {
  return (
    <>
      <div className="w-full mx-auto flex justify-center items-center gap-4">
        <div className="w-auto inline-block flex text-[#3130ED] inline-block">
          <p className="text-center md:text-left relative text-lg sm:text-2xl lg:text-3xl xl:text-3xl pb-0 uppercase">
            <span className="md:text-5xl sm:text-2xl font-wskf mr-3 tracking-[1px]">{lg}</span>
            {sm}
          </p>
        </div>
      </div>
      {isBorder && <div className="w-full border-b-[1px] border-[#3130ED]"></div>}
      {sub && <div className="text-2xl uppercase mt-10 flex items-center text-center ">{sub}</div>}
    </>
  );
};

export default RegisterHeading;
