import React, { useContext, useEffect, useState } from 'react';
import ScolarHeading from '../../components/scolarHeading';
import NavigateBackButton from '../../components/navigateBackButton/NavigateBackButton';
import TransparentBox from '../../components/transparentBox/TransparentBox';
import SchoolForm_1 from '../../components/form/SchoolForm_1';
import { plus_icon } from '../../assets/icons';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { scolar_schema, scolar_initial_value } from '../../data/scholar_form';
import InfoPopup from '../../components/popups/infoPopup';
import MissionPopup from '../../components/popups/missionPopup';
import RegisterEstablish from '../Register/registerEstablish';
import {
  getRegions,
  getAcademies,
  getLyonForm1API,
  getTimeSlots,
  registerTeacher
} from '../../services';
import { submitScolarFormAPI } from '../../services/formSubmit';
import TakenPopup from '../../components/popups/takenPopup';
import RegisterCollage from '../Register/registerCollage';
import ResponsePopup from '../../components/popups/responsePopup';
import { useParams } from 'react-router-dom';
import Loader from '../../components/common/loader';

const ScolarForm_1 = () => {
  const { id } = useParams();
  const [isInfo, setIsInfo] = useState(false);
  const [isMission, setIsMission] = useState(false);
  const [isSlot, setIsSlot] = useState(false);
  const [listSlotes, setListSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [isCollage, setIsCollage] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [regions, setRegions] = useState([]);
  const [academie, setAcademies] = useState([]);
  const [activeResponsible, setActiveResponsible] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSlotSelected, setIsSlotSelected] = useState(false);

  useEffect(() => {
    getRegionsData();
    getAcademiesData();
    setSlotsList();
  }, []);

  useEffect(() => {
    if (academie.length && regions.length) {
      getFormData();
    }
  }, [academie, regions]);

  const formik = useFormik({
    initialValues: scolar_initial_value,
    validationSchema: scolar_schema,
    onSubmit: async (values) => {
      const reqData = {
        name: values.schoolData.name,
        address: values.schoolData.address,
        postalCode: values.schoolData.postalCode,
        city: values.schoolData.city,
        typeEstablishment: values.schoolData.type.value,
        region: values.schoolData.region.value,
        academy: values.schoolData.academy.value,
        responsible: values.responsible
      };
      submitScolarFormData(reqData);
    }
  });

  const getFormData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getLyonForm1API(id);
      setApiDataInForm(data);
      toast.success('données récupérées avec succès');
    } catch (e) {
      console.log('Error', e);
      if (e?.response?.data) {
        toast.error(e.response.data);
      } else {
        toast.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getRegionsData = async () => {
    try {
      const { data } = await getRegions();
      const filteredData = data.map(({ _id, name_fr }) => ({
        value: _id,
        label: name_fr
      }));
      setRegions(filteredData);
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  const getAcademiesData = async () => {
    try {
      const { data } = await getAcademies();
      const filteredData = data.map(({ _id, name }) => ({
        value: _id,
        label: name
      }));
      setAcademies(filteredData);
    } catch (e) {
      console.log('Error', e);
    }
  };

  // submit form data to BE
  const submitScolarFormData = async (reqData) => {
    try {
      setIsLoading(true);
      for (const responsible of reqData.responsible) {
        if (responsible.responsibleMissionFuture?.toLowerCase() === 'oui') {
          let body = {
            account: {
              companyName: reqData.name,
              specs: {
                currency: 'EUR'
              },
              addresses: [
                {
                  city: reqData.address,
                  countryId: 'FR',
                  isBilling: true,
                  postcode: reqData.postalCode,
                  street: reqData.region
                }
              ]
            },
            user: {
              firstname: responsible.responsibleFirstName,
              id: responsible.responsibleEmail,
              languageId: 'fr',
              lastname: responsible.responsibleName,
              roles: [],
              salutation: 'mr',
              specs: {}
            }
          };
          try {
            await registerTeacher(body);
          } catch (e) {
            toast.error(`${responsible.responsibleEmail} a déjà un accès futur à la mission`);
          }
        }
      }

      const resposneData = await submitScolarFormAPI(reqData);
      if (resposneData.status === 200) {
        setSuccess(true);
        formik.resetForm();
        toast.success('Enregistrement réussi ! Merci !');
      } else {
        toast.error('Quelque chose a mal tourné');
      }
    } catch (e) {
      if (e?.response?.status === 409) {
        setAlertData(e.response.data.slot);
        setIsAlert(true);
        toast.error(e.response.data.message);
      } else {
        toast.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // getting the list of slots from BE
  const setSlotsList = async () => {
    try {
      const { data } = await getTimeSlots();
      if (data.length) {
        setListSlots(data);
      } else {
        toast.error('Quelque chose a mal tourné');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const setApiDataInForm = async (data) => {
    const mappedValues = {
      schoolData: {
        name: data.nom_establishment,
        address: data.addresse_establishment,
        postalCode: data.code_establishment,
        city: data.ville_establishment,
        region: setRegionsDefalutValue(data.region_establishment),
        academy: setAcademiesDefalutValue(data.academie_establishment),
        type: {
          label: data.type_establishment,
          value: data.type_establishment
        }
      },
      responsible: [
        {
          responsibleName: data.lastName,
          responsibleFirstName: data.firstName,
          responsibleFunction: data.function,
          responsibleEmail: data.email,
          responsibleTelephone: data.telephone,
          responsibleMissionFuture: '',
          classes: [
            {
              classLevel: '',
              classNumbers: '',
              classVisitor: '',
              classVisitDay: '',
              classLunchSite: '',
              classEstimateTime: ''
            }
          ]
        }
      ]
    };
    formik.setValues(mappedValues);
  };

  const setAcademiesDefalutValue = (value) => {
    try {
      let compValue = value.split(' ');
      compValue = compValue[compValue.length - 1];
      const academieValue = academie.find((item) => {
        return item.label.includes(compValue);
      });
      return academieValue;
    } catch (e) {
      return '';
    }
  };

  const setRegionsDefalutValue = (value) => {
    try {
      const compValue = value.replaceAll('-', ' ').toLowerCase();
      const regionsValue = regions.find((item) => {
        return item.label.replaceAll('-', ' ').toLowerCase() === compValue;
      });
      return regionsValue;
    } catch (e) {
      return '';
    }
  };

  // used for getting for which responsible and class we selecting the slot
  const setSelectedSlotData = (data) => {
    setSelectedSlot(data);
    setIsSlot(true);
  };

  // close alert popup
  const handleAlert = () => {
    setIsAlert(false);
  };

  // handle navigation from resiter establish to register collage
  const handleNavigate = () => {
    setIsSlot(false);
    setIsCollage(true);
  };

  // handle back button of slot pages
  const handleBackClick = () => {
    setIsSlot(false);
    setIsCollage(false);
  };

  // set the seleced slot data in form according to responsible and classes
  const setSlotDataInForm = (value) => {
    setIsSlotSelected(true);
    let upDateList = listSlotes;
    upDateList = upDateList.map((item) => {
      if (item.date === value.date && item.type === value.type) {
        item.professions.map((prof) => {
          if (prof.title === value.label) {
            prof.slots.map((ti) => {
              if (ti.time === value.time) {
                ti.slot.map((st) => {
                  if (st.type === value.slot) {
                    st.isBooked = true;
                  }
                  return st;
                });
              }
              return ti;
            });
          }
          return prof;
        });
      }
      return item;
    });
    formik.setFieldValue(
      `responsible[${selectedSlot.responsible}]classes[${selectedSlot.classes}].selectedSlot`,
      value
    );
  };

  // Used for Added More Responsibiles
  const addResponsible = () => {
    const newField = {
      responsibleName: '',
      responsibleFirstName: '',
      responsibleFunction: '',
      responsibleEmail: '',
      responsibleTelephone: '',
      responsibleMissionFuture: '',
      classes: [
        {
          classLevel: '',
          classNumbers: '',
          classVisitor: '',
          classVisitDay: '',
          classLunchSite: '',
          classEstimateTime: ''
        }
      ]
    };
    formik.setFieldValue('responsible', [...formik.values.responsible, newField]);
    const newResponse = formik.values.responsible.length;
    setActiveResponsible(() => newResponse);
  };

  // Used for Added more Classes in the Active Responsibles
  const addClass = () => {
    const newField = {
      classLevel: '',
      classNumbers: '',
      classVisitor: '',
      classVisitDay: '',
      classLunchSite: '',
      classEstimateTime: ''
    };
    formik.setFieldValue(`responsible[${activeResponsible}]classes`, [
      ...formik.values.responsible[activeResponsible].classes,
      newField
    ]);
  };

  const removeResponsible = async (index) => {
    setActiveResponsible(index - 1);
    const updatedData = [...formik.values.responsible];
    updatedData.splice(index, 1);
    formik.setFieldValue(`responsible`, updatedData);
  };

  const removeClass = (resId, classId) => {
    const updatedData = [...formik.values.responsible];
    updatedData[resId].classes.splice(classId, 1);
    formik.setFieldValue(`responsible`, updatedData);
  };

  return (
    <>
      {!isSlot && !isCollage && (
        <div className={`container mx-auto bg-white pb-2 !px-[90px] !text-[#3130ED]`}>
          <form onSubmit={formik.handleSubmit} className="block">
            <div className="h-[80px] flex items-center sm:mb-9 mb-4 pt-[50px] md:pt-[100px]">
              <div className="w-[8%] md:w-[6%]">
                <NavigateBackButton color={'purple'} />
              </div>
              <div className="pl-3 md:pl-9 w-full lg:w-fit">
                <ScolarHeading sm={'Groupes scolaires -'} lg={'VISITE SUR SITE à LYON'} />
              </div>
            </div>
            <div className="w-full pt-4 border-b-[1px] border-[#3130ED] md:hidden block"></div>
            <div className="md:mb-2 mb-0 pt-8">
              <div className="self-stretch text-sm md:text-lg">
                <p>
                  La Compétition nationale des métiers constitue un formidable outil de découverte
                  des métiers à travers la voie de l'excellence professionnelle.
                  <span className="md:my-0 my-5 block">
                    Inscrivez-vous pour venir découvrir plus de 70 métiers en situation réelle à
                    l'occasion de la Compétition nationale des métiers du 14 au 16 septembre 2023, à
                    Eurexpo Lyon !
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div>
                <TransparentBox extraClass="!bg-[#4b4ced2e] backdrop-blur-[26.5px] !pt-[40px] !pl-[22px] lg:pr-[134px] !pb-[40px]">
                  <div>
                    <div className="text-xl mb-3">
                      Tirez profit au maximum de votre visite à Lyon
                    </div>
                    <div className="text-base mb-2">
                      Pour préparer votre visite à Lyon, nous vous proposons deux outils à utiliser
                      seuls ou en combinaison pour lesquels vous pouvez vous enregistrer plus bas :
                    </div>
                    <div className="ml-5">
                      <ul role="list" className="list-disc">
                        <li className="mb-3">
                          <div>
                            <div>
                              <span className="font-wskf text-xl md:text-2xl tracking-[2px]">
                                PaRCOURs ACCompAGNés :
                              </span>
                              Nous vous proposons la possibilité de réserver un parcours accompagné
                              selon différentes thématiques (4 métiers – durée 1h15).{' '}
                            </div>
                            <span
                              onClick={() => setIsInfo(true)}
                              className="mt-[5px] border-b-[1px] border-[#3130ED] cursor-pointer">
                              Plus d’informations ICI
                            </span>
                          </div>
                        </li>
                        <li className="mb-3">
                          <div>
                            <span className="font-wskf text-xl md:text-2xl tracking-[2px]">
                              mIssIOn FUTURe
                            </span>{' '}
                            permet aux élèves de découvrir leurs forces et potentiels. Ils sont
                            ensuite mis en relation avec les CHAMPIONS WorldSkills France et les
                            Participants à la Compétition nationale des métiers de leur région avec
                            des dispositions personnelles (forces) similaires. Ceci permet aux
                            élèves de se projeter dans de nouveaux métiers qu’ils n’auraient
                            peut-être pas envisagés et de créer un lien avec les Compétiteurs qu’ils
                            pourront découvrir sur site à Lyon.
                          </div>
                          <span
                            onClick={() => setIsMission(true)}
                            className="mt-[5px] border-b-[1px] border-[#3130ED] cursor-pointer">
                            Plus d’informations ICI
                          </span>
                        </li>
                        <li>
                          <div>
                            <span className="font-wskf text-xl md:text-2xl tracking-[2px]">
                              KIT péDaGoQIue & PaRCouRs auToNomes :
                            </span>{' '}
                            Ces outils ne nécessitent pas d’enregistrement et sont consultables en
                            suivant les liens au bas de la page.
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TransparentBox>
              </div>
            </div>
            <div>
              <div>
                <SchoolForm_1
                  t1={'Responsable'}
                  t2={'CLASSE'}
                  setFieldValue={formik.setFieldValue}
                  schoolFields={formik.values.schoolData}
                  ResponsibleFields={formik.values.responsible}
                  errors={formik.errors}
                  touched={formik.touched}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  selectSlot={setSelectedSlotData}
                  regions={regions}
                  academie={academie}
                  removeClass={removeClass}
                  removeResponsible={removeResponsible}
                  activeResponsible={activeResponsible}
                  setActiveResponsible={setActiveResponsible}
                />
              </div>
            </div>
            <div className="my-6">
              <div className="sm:flex lg:mx-4 my-10 gap-5">
                <div className="sm:w-[40%] w-[60%] mb-4 lg:mb-0 flex items-center sm:justify-center justify-left">
                  <button
                    // disabled={isSubmitting}
                    type="submit"
                    className="p-3 w-full text-base rounded-full bg-[#3130ED] text-white">
                    Validez votre inscription
                  </button>
                </div>
                <div className="w-[80%] sm:w-[60%] flex items-center justify-left">
                  <p className="p-0">
                    Après avoir validé votre choix, vous recevrez un e-mail de confirmation.
                    Vérifiez votre dossier courrier indésirable.
                  </p>
                </div>
              </div>
            </div>
            <hr className="bg-[#3130ED] h-0.5 mb-10"></hr>
            <div className="mb-10">
              <span
                onClick={() => addClass()}
                className="rounded-full w-fit flex gap-2 md:gap-5 items-center mb-3 justify-center bg-[#4b4ced2e] backdrop-blur-[26.5px] p-2 cursor-pointer">
                <div className="max-w-[13px] flex sm:max-w-[20px]">{plus_icon}</div>
                <div className="text-xs	md:text-base">
                  Ajouter une classe avec le même responsable
                </div>
              </span>
              <span
                onClick={() => addResponsible()}
                className="rounded-full w-fit flex gap-2 md:gap-5 items-center mb-3 justify-center bg-[#4b4ced2e] backdrop-blur-[26.5px] p-2 cursor-pointer">
                <div className="max-w-[13px] flex sm:max-w-[20px]">{plus_icon}</div>
                <div className="text-xs	md:text-base">
                  Ajouter une classe avec un nouveau responsable
                </div>
              </span>
            </div>
            <div>
              <div>
                <TransparentBox extraClass="!bg-[#4b4ced2e] backdrop-blur-[26.5px] !pt-[40px] !pl-[22px] lg:pr-[134px] !pb-[40px]">
                  <div>
                    <div className="text-xl mb-3">
                      Outils complémentaires pour préparer votre visite à Lyon
                    </div>
                    <div className="text-base mb-2">
                      Les outils suivants ne nécessitent pas d’enregistrement et sont consultables
                      en suivant les liens ci-dessous :
                    </div>
                    <div className="ml-5">
                      <ul role="list" className="list-disc">
                        <li className="mb-3">
                          <div>
                            <div>
                              <span className="font-wskf text-xl md:text-2xl tracking-[2px]">
                                KIT PéDaGoGIQUe :
                              </span>
                              WorldSkills France a développé, en collaboration avec l'Onisep, un kit
                              pédagogique complet permettant de faciliter la découverte des métiers
                              en compétition et le travail de réflexion sur l'orientation et la
                              construction du projet professionnel. Ce kit est consultable et
                              téléchargeable{' '}
                              <a
                                href="https://www.worldskills-france.org/sinformer/documentation/kit-pedagogique/"
                                className="underline">
                                ICI
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="mb-3">
                          <div>
                            <span className="font-wskf text-xl md:text-2xl tracking-[2px]">
                              PaRCoURs AUToNomes :
                            </span>
                            Découvrez des métiers en compétition au travers de 15 parcours, par
                            profil, par thème, par secteur ou de manière transversale. Chaque
                            parcours peut être réalisé dans n'importe quel ordre. Ces parcours sont
                            consultables{' '}
                            <a
                              href="https://www.worldskills-france.org/app/uploads/2023/04/46FNAT_Programmes-decouverte.pdf"
                              className="underline">
                              ICI
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TransparentBox>
              </div>
            </div>
          </form>
        </div>
      )}
      {isSlot && (
        <RegisterEstablish
          listSlotes={listSlotes}
          isSlot={isSlot}
          setIsSlot={setIsSlot}
          handleNavigate={handleNavigate}
          selectedSlotData={setSlotDataInForm}
          handleBackClick={handleBackClick}
        />
      )}
      {isCollage && (
        <RegisterCollage
          listSlotes={listSlotes}
          isCollage={isCollage}
          setIsCollage={setIsCollage}
          selectedSlotData={setSlotDataInForm}
          handleBackClick={handleBackClick}
        />
      )}
      {isInfo && <InfoPopup isInfo={isInfo} setIsInfo={setIsInfo} />}
      {isAlert && <TakenPopup isAlert={isAlert} alertData={alertData} setIsAlert={handleAlert} />}
      {isMission && <MissionPopup isMission={isMission} setIsMission={setIsMission} />}
      {isSuccess && (
        <ResponsePopup
          title={'Enregistrement réussi !'}
          image={'/images/thankyou.png'}
          subText={`Vous recevrez un e-mail de confirmation. Vérifiez votre dossier courrier indésirable.`}
          isCloseButton={true}
          setSuccess={setSuccess}
        />
      )}
      {isSlotSelected && (
        <ResponsePopup
          title={'mERCI POUR VOTRE RÉSERVATION'}
          image={'/images/thankyou.png'}
          subText={`Finalisez votre enregistrement et validez-le à la fin du formulaire.`}
          buttonText={`Retourner à l’enregistrement`}
          isCloseButton={false}
          setSuccess={setIsSlotSelected}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default ScolarForm_1;
