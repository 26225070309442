import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import LyonLandingPage from './pages/landing/Landing';
import Form_1 from './pages/form_1/form_1';
import Form_2 from './pages/form_2/form_2';
import InPerson from './pages/in-person';
import Digital from './pages/digital';
import ScolarForm from './pages/scolarForm';

import 'react-toastify/dist/ReactToastify.css';
import DigitalParticipant from './pages/digitalParticipant';
import OtherGroup from './pages/otherGroup';
import RegisterEstablish from './pages/Register/registerEstablish';
import RegisterCollage from './pages/Register/registerCollage';
import NewLanding from './pages/newLanding';
import ScholarsFormData from './pages/formDisplay/scholars';
import OthersFormData from './pages/formDisplay/others';
import ScolarForm_1 from './pages/scolarForm_1';
import MetropoleLyon from './pages/metropoleLyon';
import DigitalData from './pages/formDisplay/digital';
import AcademyData from './pages/formDisplay/academy';
import SelectSlot from './pages/selectSlot';
import EditScholarForm from './pages/editForm';
import EditOtherForm from './pages/editOtherForm';
import SlotsWithBookings from './pages/slotsWithBooking';

import { ScrollToTop } from './util/helpers';

function App() {
  const [headerType, setHeaderType] = useState('live.competition');

  useEffect(() => {
    if (headerType === 'simple') {
      document.body.classList.add('bg-lyon-purple');
      document.body.classList.add('text-white');
    } else {
      document.body.classList.remove('bg-lyon-purple');
      document.body.classList.remove('text-white');
    }
  }, [headerType]);

  return (
    <>
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <Header headerType={headerType} />
        <Routes>
          <Route
            path="/education-nationale"
            element={<LyonLandingPage setHeaderType={() => setHeaderType('simple')} />}
          />
          <Route
            path="/form_1"
            element={<Form_1 setHeaderType={() => setHeaderType('simple')} />}
          />
          <Route
            path="/form_2"
            element={<Form_2 setHeaderType={() => setHeaderType('simple')} />}
          />
          <Route path="/in-person/:id" element={<InPerson />} />
          <Route path="/digital/:id" element={<Digital />} />
          <Route path="/scolar-form-1/:id" element={<ScolarForm_1 />} />
          <Route path="/scolar-form" element={<ScolarForm />} />
          <Route path="/other-group" element={<OtherGroup />} />
          <Route path="/digital-participant" element={<DigitalParticipant />} />
          <Route path="/mission-future" element={<MetropoleLyon />} />
          <Route path="/register-establish" element={<RegisterEstablish />} />
          <Route path="/register-collage" element={<RegisterCollage />} />
          <Route path="/scholars-form-data" element={<ScholarsFormData />} />
          <Route path="/others-form-data" element={<OthersFormData />} />
          <Route path="/digitals-form-data" element={<DigitalData />} />
          <Route path="/academy-data/:id" element={<AcademyData />} />
          <Route path="/selectSlot" element={<SelectSlot />} />
          <Route path="/edit-form" element={<EditScholarForm />} />
          <Route path="/edit-other-form" element={<EditOtherForm />} />
          <Route path="/slots-with-bookings" element={<SlotsWithBookings />} />

          <Route path="/" element={<NewLanding />} />
          {/* <Route path="/" element={<LyonLandingPage />} /> */}

          {/* Not found */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer headerType={headerType} />
      </Router>
    </>
  );
}

// deploy

export default App;
