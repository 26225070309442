import React from 'react';

export default function Radio({ extraClass = '', isBooked, data, theme, currentSlot = false }) {
  return (
    <label
      className={`${
        theme === 'white' ? 'mr-2 md:mr-4' : 'w-full'
      } block mb-2 flex justify-between items-center cursor-pointer ${extraClass}`}>
      {theme === 'white' ? (
        <span className="mr-1 text-xs md:text-base">{data.label[2]}</span>
      ) : (
        <span className="block text-base mb-2 uppercase">
          <span className="font-wskf text-3xl pr-1 tracking-[2px] normal-case">
            {data.label[0]}
          </span>
          {data.label[1] && <span>{data.label[1]}</span>}
        </span>
      )}
      <input
        className={`${
          theme === 'white' ? 'border-[#3130ED] border select_radio' : 'border-2 mr-2'
        } appearance-none h-8 w-8 cursor-pointer rounded-md leading-tight ${
          currentSlot ? 'currentSlot' : isBooked ? 'booked' : ''
        }`}
        type="radio"
        {...data}
      />
    </label>
  );
}
