import React from 'react';
import { Link } from 'react-router-dom';
import ResponsiveVimeoVideo from '../../components/responsiveVimeoVideo/ResponsiveVimeoVideo';

const NewLanding = () => {
  return (
    <>
      <div className={`container mx-auto bg-white pb-2 !px-[90px] !text-[#3130ED]`}>
        <div className="gap-x-9 mb-12 mb-sm-4 pt-[50px] md:pt-[100px]">
          <div className="w-full mx-auto flex justify-center items-center gap-4">
            <div className="w-auto text-[#3130ED] gap-0 flex flex-col justify-center items-center sm:gap-2">
              <div className="flex flex-col items-center justify-center">
                <b className="relative sm:text-2xl lg:text-3xl xl:text-3xl pb-0 uppercase mb-2">
                  Inscriptions groupes
                </b>
                <div className="mt-8">
                  <p className="text-center relative sm:text-xl lg:text-xl xl:text-xl pb-0 uppercase">
                    DÉCOUVREZ
                    <span className="md:text-3xl text-2xl font-wskf ml-0 md:ml-[10px] tracking-[2px] uppercase">
                      800 jeunes
                    </span>{' '}
                    jeunes passionNés de leur métier en compétition
                  </p>
                  <div className="w-full pt-4 border-b-[1px] border-[#3130ED] block"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-7 py-5 rounded-md border border-[#3130ED] mb-10">
          <div>
            <div>
              <p className="p-0">
                800 jeunes passionés de leur métier représentent leur région lors de la Compétition
                nationale des métiers, nommée historiquement Olympiades des Métiers.
              </p>
              <p className="">
                Les meilleurs d’entre eux seront sélectionnés pour représenter la France lors de la
                compétition mondiale WorldSkills à Lyon en septembre 2024.
                <br />
                La Compétition nationale offre une opportunité unique pour découvrir la richesse et
                la valeur des métiers grâce à une mise en relation sans a priori avec jeunes modèles
                de la région - à distance en numérique ou en présentiel à Lyon.
              </p>
              <p className="">
                Les Compétitions WorldSkills en{' '}
                <span className="text-lg sm:text-2xl font-wskf tracking-[1px] uppercase">
                  {' '}
                  90 secondes
                </span>
              </p>
            </div>
            <div>
              <ResponsiveVimeoVideo
                url={
                  'https://player.vimeo.com/video/796027338?h=f09b0755d8&color=ff9933&title=0&byline=0&portrait=0'
                }
              />
            </div>
          </div>
        </div>
        <div className="bg-[#F9F9F9] p-3 rounded-md mb-10">
          <div className="">
            <div className="w-full bg-[#4b4ced2e] p-2 rounded-lg">
              <p className="text-lg p-0">
                <b className="text-lg tracking-[1px] sm:text-xl p-0 sm:mr-2">
                  PARTICIPATION NUMÉRIQUE :
                </b>
                Groupes scolaires & autres groupes
              </p>
              <p className="pb-0">
                Note : Vous pouvez profiter de la Compétition nationale en numérique tout au long de
                l’automne.{' '}
              </p>
            </div>
            <div className="px-8 pt-8 pb-3">
              <ul className="list-disc">
                <li>
                  <p>
                    Vous n'avez pas la possibilité de venir à Eurexpo Lyon, mais souhaitez vivre la
                    compétition nationale des métiers à distance avec votre{' '}
                    <span className="text-2xl font-wskf tracking-[2px]">classe</span> (ou{' '}
                    <span className="text-2xl font-wskf tracking-[2px]">autre groupe</span>) ?
                    Cliquez{' '}
                    <Link
                      to="/digital-participant"
                      className="text-xl font-wskf tracking-[1px] underline cursor-pointer">
                      ICI
                    </Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="">
            <div className="w-full bg-[#4b4ced2e] p-2 rounded-lg">
              <p className="text-lg p-0">
                <b className="text-lg tracking-[1px] sm:text-xl p-0 mr-2">VISITE SUR SITE :</b>
                Voulez-vous inscrire un ...
              </p>
            </div>
            <div className="px-8 py-8">
              <ul className="list-disc">
                <li>
                  <p>
                    <span className="text-xl sm:text-2xl font-wskf tracking-[2px]">
                      GRoUPes SCoLaIRes
                    </span>{' '}
                    ? Cliquez{' '}
                    <Link
                      to="/scolar-form"
                      className="text-xl font-wskf tracking-[1px] underline cursor-pointer">
                      ICI
                    </Link>
                  </p>
                </li>
                <li>
                  <p>
                    <span className="text-xl sm:text-2xl font-wskf tracking-[2px]">
                      AUTRes GRoUPes
                    </span>{' '}
                    (Pôle Emploi, Mission Locale, Associations…) ? Cliquez{' '}
                    <Link
                      to="/other-group"
                      className="text-xl font-wskf tracking-[1px] underline cursor-pointer ">
                      ICI
                    </Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="my-8 flex w-full justify-center items-center">
          <div className="flex">
            <div className="w-20 sm:w-fit">
              <span className="md:text-3xl sm:text-2xl font-wskf tracking-[2px] uppercase">
                Contact :{' '}
              </span>
            </div>
            <div>
              <p className="p-0 sm:pt-3 ml-4">
                Vous avez une question ? Écrivez-nous à <br />
                <span className="underline">visite-47worldskills@cofom.org</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewLanding;
