import React, { useEffect, useState } from 'react';
import Input from './Input';
import { stringArrayToOptionsList } from '../../util/helpers';
import typeList from '../../data/type.list';
import { getRegions, getAcademies } from '../../services';

const DigitalForm = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => {
  const [regions, setRegions] = useState([]);
  const [academie, setAcademies] = useState([]);

  useEffect(() => {
    getRegionsData();
    getAcademiesData();
  }, []);

  const getRegionsData = async () => {
    try {
      const { data } = await getRegions();
      const filteredData = data.map(({ _id, name_fr }) => ({
        value: _id,
        label: name_fr
      }));
      setRegions(filteredData);
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  const getAcademiesData = async () => {
    try {
      const { data } = await getAcademies();
      const filteredData = data.map(({ _id, name }) => ({
        value: _id,
        label: name
      }));
      setAcademies(filteredData);
    } catch (e) {
      console.log('Error', e);
    }
  };

  return (
    <>
      <div>
        <div className="mb-5 text-lg">Données de l’établissement / de l’organisation</div>
        <div className="">
          <div className="grid grid-rows-1 md:grid-cols-2 gap-y-3 gap-x-12">
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Nom de l’établissement / organisation*`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'name',
                    value: values.name,
                    touched: touched.name,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.name && !!errors?.name ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.name}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Adresse de l’établissement / organisation*`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'address',
                    value: values.address,
                    touched: touched.address,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.address && !!errors?.address ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.address}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Code postal de l’établissement / organisation*`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'postalCode',
                    value: values.postalCode,
                    touched: touched.postalCode,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.postalCode && !!errors?.postalCode ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.postalCode}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Ville de l’établissement / organisation*`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'city',
                    value: values.city,
                    touched: touched.city,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.city && !!errors?.city ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.city}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Région de l’établissement / organisation*'],
                  input: {
                    options: regions,
                    placeholder: 'Sélectionner',
                    name: 'region',
                    value: values.region,
                    touched: touched.region,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('region', val);
                    }
                  }
                }}
              />
              {!!touched?.region && !!errors?.region ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.region}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Académie de l’établissement*'],
                  input: {
                    options: academie,
                    placeholder: 'Sélectionner',
                    name: 'academy',
                    value: values.academy,
                    touched: touched.academy,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('academy', val);
                    }
                  }
                }}
              />
              {!!touched?.academy && !!errors?.academy ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.academy}</p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                type={'select'}
                data={{
                  label: ['', '', 'Type d’établissement / organisation*'],
                  input: {
                    options: stringArrayToOptionsList(typeList),
                    placeholder: 'Sélectionner',
                    name: 'type',
                    value: values.type,
                    touched: touched.type,
                    onBlur: handleBlur,
                    onChange: (val) => {
                      setFieldValue('type', val);
                    }
                  }
                }}
              />
              {!!touched?.type && !!errors?.type ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">{errors.type}</p>
              ) : null}
            </span>
          </div>
          <div>
            <div className="mt-8 mb-3 text-lg">Responsable de classe ou de groupe</div>
          </div>
          <div className="grid grid-rows-1 md:grid-cols-2 gap-y-3 gap-x-12">
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Nom *`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'responsibleFirstName',
                    value: values.responsibleFirstName,
                    touched: touched.responsibleFirstName,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.responsibleFirstName && !!errors?.responsibleFirstName ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.responsibleFirstName}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Prénom *`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'responsibleLastName',
                    value: values.responsibleLastName,
                    touched: touched.responsibleLastName,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.responsibleLastName && !!errors?.responsibleLastName ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.responsibleLastName}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Fonction *`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'responsibleFunction',
                    value: values.responsibleFunction,
                    touched: touched.responsibleFunction,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.responsibleFunction && !!errors?.responsibleFunction ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.responsibleFunction}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Email *`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'responsibleEmail',
                    value: values.responsibleEmail,
                    touched: touched.responsibleEmail,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.responsibleEmail && !!errors?.responsibleEmail ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.responsibleEmail}
                </p>
              ) : null}
            </span>
            <span className="block">
              <Input
                theme={'white'}
                data={{
                  label: ['', '', `Télèphone *`],
                  input: {
                    placeholder: 'écrire ici',
                    name: 'responsibleTelephone',
                    value: values.responsibleTelephone,
                    touched: touched.responsibleTelephone,
                    onBlur: handleBlur,
                    onChange: handleChange
                  }
                }}
              />
              {!!touched?.responsibleTelephone && !!errors?.responsibleTelephone ? (
                <p className="text-red-400 pb-0 ml-4 text-xs uppercase">
                  {errors.responsibleTelephone}
                </p>
              ) : null}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalForm;
